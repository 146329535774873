export const environment = {
    production: false,
    APP_URL: 'https://fash-pms.stg.fashquik.com/',
    API_URL: 'https://pms-api.stg.fashquik.com/api',
    API_OUMS_URL:'https://oums-api.stg.fashquik.com/api',
    firebase: {
        apiKey: 'AIzaSyCVfZvmT_m_814MXHrkJMDUXpYS8WFg114',
        authDomain: 'fashquik-fakapp.firebaseapp.com',
        projectId: 'fashquik-fakapp',
        storageBucket: 'fashquik-fakapp.appspot.com',
        messagingSenderId: '246594818888',
        appId: '1:246594818888:web:cebc23e81fc7a45526197d',
        measurementId: 'G-4WSCE8FMRQ',
    },
    firebaseConfig: {
        apiKey: 'AIzaSyCVfZvmT_m_814MXHrkJMDUXpYS8WFg114',
        authDomain: 'fashquik-fakapp.firebaseapp.com',
        projectId: 'fashquik-fakapp',
        storageBucket: 'fashquik-fakapp.appspot.com',
        messagingSenderId: '246594818888',
        appId: '1:246594818888:web:cebc23e81fc7a45526197d',
        measurementId: 'G-4WSCE8FMRQ',
    }
  
}