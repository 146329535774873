<app-layout>
    <div class="main-container">
        <div class="form-header-container">
            <h3 class="heading">{{ commissionSlabId ? 'Update' : 'Create' }} Commission Slab</h3>
        </div>
        <div class="form-main-container">
            <div class="form-inner-container">
              <form class="form-main" [formGroup]="createCommissionForm">
                <div class="form-main-inner row">
                  <div class="col-lg-4 col-sm-4 col-xs-12 col-md-6 form-field pb-1">
                    <mat-form-field appearance="outline" class="w-100">
                     <mat-label>Slab Name</mat-label>
                      <input formControlName="slabName" type="text" matInput placeholder="Enter Slab Name" />
                      <mat-error *ngIf="createCommissionForm?.get('slabName')?.hasError('required')">Slab Name is required</mat-error>
                      <mat-error *ngIf="createCommissionForm?.get('slabName')?.hasError('pattern')">Slab Name field cannot be blank</mat-error>
                    </mat-form-field>
                  </div>


                  <div class="col-lg-4 col-sm-4 col-xs-12 col-md-6 form-field pb-1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Applicable for</mat-label>
                        <mat-select formControlName="applicable" required>
                            <mat-option value="">Select Type</mat-option>
                            <mat-option value="DeliveryExecutive">Delivery Executive</mat-option>
                        </mat-select>
                        <mat-error *ngIf="createCommissionForm?.get('applicable')?.hasError('required')">Applicable For is required</mat-error>
                    </mat-form-field>
                   
                  </div>

                  <div class="col-lg-4 col-sm-4 col-xs-12 col-md-6 form-field pb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="state" (selectionChange)="fetchCitiesByState($event.value)">
                        <mat-option value="">Select State</mat-option>
                        <mat-option *ngFor="let state of allStates" [value]="state?._id">{{ state?.name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="createCommissionForm?.get('state')?.hasError('required')">State is required</mat-error>
                    </mat-form-field>
                  </div>
    
                  <div class="col-lg-4 col-sm-4 col-xs-12 col-md-6 form-field pb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>City</mat-label>
                      <mat-select formControlName="city">
                        <mat-option value="">Select City</mat-option>
                        <mat-option *ngFor="let city of allCities" [value]="city?.id">{{ city?.city }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="
                      createCommissionForm?.get('city')?.hasError('required')
                                ">City is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="form-main-inner row">
                  <div class="col-lg-4 col-sm-4 col-xs-12 col-md-6 form-field pb-1">
                    <label class="label-form">Status</label>
                    <mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="isActive"></mat-slide-toggle>
                  </div>
                </div>

                <div class="form-main-action">
                  <button mat-raised-button color="primary" (click)="onCommissionSlabSubmit()">
                    {{ commissionSlabId ? 'Update' : 'Create' }} Commission Slab
                  </button>
                </div>
              </form>
            </div>
        </div>
    </div>
</app-layout>