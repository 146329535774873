import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductCatelogueServiceService {
  private outOfStockSubject = new BehaviorSubject<number>(0);
  outOfStock$ = this.outOfStockSubject.asObservable();
  activeProductData$: Subject<boolean> = new Subject<boolean>();
  productCatalogueId: any;
  id: any;
  isEditing=false;
  productId: any;
  variantType: any;
  endpoints = {
    productCatalogue: '/product',
    loggedInvendorProduct: '/product/vendor-product-list',
    uploadFile: '/upload',
    multipleUploadFile: '/upload/uploadMultiImage',
    productVariant: '/product-variants',
    updateBasicInfo: '/product/edit-basic-information',
    getproductVariant: '/product/product-variant',
    variantInfo: '/product/variant-info',
    updateProductDetails: '/product/updateVariantByID',
    productAssets: '/product/addAssest',
    categories: '/categories',
    brands: '/brands',
    updateProduct: '/product/updateProductDetails',
    updateVariantById: '/product/updateVariantByID',
    updateMultipleVariantById:'/product/update-multiple-VariantByID',
    deleteProductCatalogue: '/product/soft-delete',
    deleteProductVariant: '/product/delete-variant',
    draftProduct: '/product/vendor-product-list?status=draft',
    updateMultipleProduct: '/product/update-multiple-product',
    lowOfStock:'/product/vendor-lowstock-list/',
    outOfStock:'/product/out-of-stock-list',
    sizeChartUrl:'/product/addSizeChart/'

  };
  private apiUrl = environment.API_URL;
  constructor(private http: HttpClient, private _authService: AuthService) {}
  getCategories(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpoints.categories}`, {
      headers: this._authService.getHeaders(),
    });
  }
  getBrands(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpoints.brands}?limit=${1000}`, {
      headers: this._authService.getHeaders(),
    });
  }
  getProduct(): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.loggedInvendorProduct}?limit=5000`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  getDraftProduct(): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.draftProduct}&limit=5000`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }

  addProduct(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}${this.endpoints.productCatalogue}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  editProduct(data: any, id: any): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}${this.endpoints.updateBasicInfo}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  uploadImg(data: any) {
    return this.http.post(
      `${this.apiUrl}${this.endpoints.uploadFile}`,
      data,
      {}
    );
  }
  uploadMultiImg(data: any) {
    return this.http.post(
      `${this.apiUrl}${this.endpoints.multipleUploadFile}`,
      data,
      {}
    );
  }
  addProductImage(data: any, id: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}${this.endpoints.productAssets}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  getProductById(id: any): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.productCatalogue}/${id}`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  updateProduct(id: any, data: any): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.endpoints.updateProduct}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  updateMultiVariantById(id: any, data: any): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.endpoints.updateVariantById}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  updateMultiVariant(id: any,data: any): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.endpoints.updateMultipleVariantById}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  deleteProductCatalogue(id: string): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${this.endpoints.deleteProductCatalogue}/${id}`,
      { headers: this._authService.getHeaders() }
    );
  }

  // Multi Variant APIs
  deleteProductVariants(id: string, variantId: any): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${this.endpoints.deleteProductVariant}/${id}?variantId=${variantId}`,
      { headers: this._authService.getHeaders() }
    );
  }

  getVariants(): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.productVariant}`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  getVariantById(id: any): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.getproductVariant}/${id}`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  postVariantInfo(id: any, data: any): Observable<any[]> {
    return this.http.post<any>(
      `${this.apiUrl}${this.endpoints.variantInfo}/${id}`,
      data,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }

  // Subject
  private productIdSubject = new Subject<string>();
  productId$ = this.productIdSubject.asObservable();

  sendProductId(productId: string) {
    this.productIdSubject.next(productId);
  }

  getDataById(id: any) {
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.productCatalogue}/${id}`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  updateMultipleProduct(data: any[]): Observable<any> {
    return this.http.put(`${this.apiUrl}${this.endpoints.updateMultipleProduct}`, data, {
      headers: this._authService.getHeaders(),
    });
  }
  getlowStock(){
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.lowOfStock}`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  getOutOfStock(){
    return this.http.get<any>(
      `${this.apiUrl}${this.endpoints.outOfStock}?limit=5000`,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
  updateOutOfStock(count: number) {
    this.outOfStockSubject.next(count);
  }
  uploadsizeChartUrl(id: any, sizeChartPayload: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${this.endpoints.sizeChartUrl}${id}`,
      sizeChartPayload,
      {
        headers: this._authService.getHeaders(),
      }
    );
  }
}
