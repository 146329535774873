import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../delivery-executive.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rider-list',
  templateUrl: './rider-list.component.html',
  styleUrls: ['./rider-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiderListComponent implements OnInit{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 10;
  currentDate:Date = new Date();
  isActive= true;

  calculateSerialNumber(indexOnPage: number): number {
    return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any) {
    this.currentPageIndex = event.pageIndex;
  }
  displayedColumns: string[] = [
    'srno',
    'riderName',
    'mobileNo',
    'vehicleDetail',
    'status',
    'loginDuration'
  ];
  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade: AppStoreFacade
  ) { }
  ngOnInit(): void {
    this.getRiderList()
  }
  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
  }

  redirectActions(action: string, id?: number) {
    if (action === 'view') {
      this._router.navigate(['/delivery-executive/rider-details', id]);
    }
  }
  getRiderList(id?: any) {
    this._appStoreFacade.updateLoading(true);
    this._executiveService.getRiderList(id).pipe(
      tap((response: any) => {
        this._appStoreFacade.updateLoading(false);
        if (response?.success) {
          this.dataSource.data = response?.data.map((rider: any) => ({
            ...rider,
            isActive: rider.status.trim().toLowerCase() === 'online',
          }));
          this.dataSource.paginator = this.paginator;
        } else {
          this._snackbarService.showError(response?.message);
        }
        this._cdr.markForCheck();
      })
    ).subscribe(
      () => {},
      (error: any) => {
        this._snackbarService.showError(error?.error?.message);
        this._cdr.markForCheck();
      }
    );
  }
  
  refreshRiderList() {
    this.getRiderList();
    this._snackbarService.showSuccess('Data refreshed successfully');
  }

  onStatusToggle(newStatus: boolean, rider: any) {
    this._snackbarService.confirmStatusChange(newStatus).then((result) => {
      if (result.isConfirmed) {
        const status = newStatus ? 'Online' : 'Offline'; 
        this.getRiderStatus(rider, status);
        this._cdr.markForCheck();
      } else {
        rider.isActive = !newStatus;
        this._cdr.markForCheck();
      }
    });
  }

  getRiderStatus(rider: any, status: string) {
    const payload = {
      executiveId:rider._id,
      status: status,
    };
  
    this._executiveService.getRiderStatus(payload).subscribe(
      (response: any) => {
        if (response.success) {
          rider.status = status.charAt(0).toUpperCase() + status.slice(1);
          rider.isActive = status === 'Online';
          this._snackbarService.showSuccess(response?.message);
          this._cdr.markForCheck();
        } else (error:any)=>{
          rider.isActive = !rider.isActive;
          this._snackbarService.showError(error?.error?.message);
        }
        this._cdr.markForCheck();
      },
      (error: any) => {
        rider.isActive = !rider.isActive;
        this._snackbarService.showError(error?.error?.message);
        this._cdr.markForCheck();
      }
    );
  }
}