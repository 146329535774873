<app-layout>
    <div class="main-container">
        <ng-container >
            <div class="form-main-container">
                <div class="form-inner-container">
                  <mat-card class=" form-main-inner row">
                    <div class="col-lg-12 col-sm-12 col-xs-12 p-0 form-field">
                      <mat-stepper #stepper [selectedIndex]="vendorCurrentStep" (selectionChange)="updateExecutiveCurrentStep($event.selectedIndex)">
                        <mat-step>
                          <ng-template matStepLabel>Personal Details </ng-template>
                          <app-delivery-executive-step-one></app-delivery-executive-step-one>
                  
                        </mat-step>
                        <mat-step>
                          <ng-template matStepLabel>Employment </ng-template>
                         <app-delivery-executive-step-two></app-delivery-executive-step-two>
                  
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Bank Account</ng-template>
                            <app-delivery-executive-step-three></app-delivery-executive-step-three>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Training Acknowledgement</ng-template>
                            <app-delivery-executive-step-four></app-delivery-executive-step-four>
                        </mat-step>
                      </mat-stepper>
                    </div>
                  </mat-card>
                </div>
              </div>
        </ng-container>

    </div>
  </app-layout>
    