import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { AddExecutiveComponent } from './add-executive/add-executive.component';
import { UpdateExecutiveComponent } from './update-executive/update-executive.component';
import { DeliveryExecutiveService } from './delivery-executive.service'
import { CommissionSlabComponent } from './commission-slab/commission-slab.component';
import { AddEditCommissionSlabComponent } from './commission-slab/add-edit-commission-slab/add-edit-commission-slab.component';
import { ViewCommissionSlabComponent } from './commission-slab/view-commission-slab/view-commission-slab.component';
import { AddEditFeeRengeComponent } from './commission-slab/add-edit-fee-renge/add-edit-fee-renge.component';
import { OngoingTaskComponent } from './ongoing-task/ongoing-task.component';
import { RiderListComponent } from './rider-list/rider-list.component';
import { AssignedOrdersPopupComponent } from './ongoing-task/ongoing-list/assigned-orders-popup/assigned-orders-popup.component';

const routes: Routes = [
  {path:'executive-list',component:ExecutiveListComponent},
  {path:'add-executive',component:AddExecutiveComponent},
  {path:'update-executive/:id',component:UpdateExecutiveComponent},
  {path: 'commission-slab', component:CommissionSlabComponent},
  { path: 'commission-slab/edit/:id', component: AddEditCommissionSlabComponent },
  { path: 'commission-slab/create', component: AddEditCommissionSlabComponent },  
  { path: 'commission-slab/view/:id', component: ViewCommissionSlabComponent },
  { path: 'commission-slab/fee-renge/create', component: AddEditFeeRengeComponent},
  { path: 'commission-slab/fee-renge/edit/:id', component: AddEditFeeRengeComponent},
  { path: 'ongoing-task', component:OngoingTaskComponent },
  { path: 'rider-list', component:RiderListComponent},
  { path: 'assign-list', component:AssignedOrdersPopupComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryExecutiveRoutingModule { }
