<app-layout>
  <div class="main-container">
    <div class="table-header-container d-flex justify-space-between align-items-center">
      <h2 class="heading">View Delivery Executive </h2>
      <button mat-raised-button class="primary-button-color lh-1"
        (click)="redirectActions('create',delveryViewId)">Create {{ deliveryType }} Range</button>
    </div>
    <div class="table-main-container">
      <div class="table-inner-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="minKm">
            <th mat-header-cell *matHeaderCellDef class="small-heading">Min Km </th>
            <td mat-cell *matCellDef="let element"> {{element?.minKm}} </td>
          </ng-container>

          <ng-container matColumnDef="maxKm">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Max Km </th>
            <td mat-cell *matCellDef="let element"> {{element?.maxKm}} </td>
          </ng-container>

          <ng-container matColumnDef="charges">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Charges </th>
            <td mat-cell *matCellDef="let element"> {{element?.charges}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Action </th>
            <td mat-cell *matCellDef="let element; let index = index" style="position: relative;">
              <div class="action-icon-box">
                <button class="mat-icon-button" matTooltip="Edit"><mat-icon class="custom-icon-edit"
                    (click)="redirectActions('edit', element._id)">edit</mat-icon></button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator class="custom-paginator" [length]="pagination.totalItems"
          [pageSize]="pagination.perPage" [showFirstLastButtons]="false" [pageSizeOptions]="[10, 20, 30]"
          [hidePageSize]="false" [pageIndex]="pagination.currentPage - 1" aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </div>
</app-layout>