<div class="main-container">
    <div class="table-search-container">
        <div class="table-search-container d-flex align-items-center justify-space-between w-100">
            <div class="table-search-inner">
                <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search"
                    class="search-input">
            </div>
            <div>
                <div class="time-range-filter">
                    <mat-form-field appearance="outline">
                        <mat-label>Time Range</mat-label>
                        <mat-select [(value)]="selectedTimeRange" (selectionChange)="onTimeRangeChange()">
                            <mat-option value="today">Today</mat-option>
                            <mat-option value="yesterday">Yesterday</mat-option>
                            <mat-option value="last7days">Last 7 Days</mat-option>
                            <mat-option value="last30days">Last 30 Days</mat-option>
                            <mat-option value="custom">Custom</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Custom Date Range Inputs, displayed only when 'Custom' is selected -->
                <div *ngIf="selectedTimeRange === 'custom'" class="custom-date-range">
                    <mat-form-field appearance="outline">
                        <mat-label>From Date</mat-label>
                        <input matInput [matDatepicker]="fromPicker" placeholder="Choose from date"
                            [(ngModel)]="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>To Date</mat-label>
                        <input matInput [matDatepicker]="toPicker" placeholder="Choose to date" [(ngModel)]="toDate">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>

                    <button mat-raised-button color="primary" (click)="applyCustomDateRange()"
                        [disabled]="!fromDate || !toDate">Apply</button>
                </div>

                <div *ngIf="dateRangeError" class="error-message">
                    Custom date range requires both From Date and To Date.
                </div>
            </div>
        </div>
    </div>
    <div class="table-main-container">
        <div class="table-inner-container">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="srno">
                    <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
                    <td mat-cell *matCellDef="let element; let i = index"> {{ calculateSerialNumber(i) }} </td>
                </ng-container>

                <ng-container matColumnDef="orderId">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Order Id</th>
                    <td mat-cell *matCellDef="let element" (click)="openAllOrder(element)"> {{element?.orderID}} </td>
                </ng-container>

                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Customer Name </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.name}} </td>
                </ng-container>

                <ng-container matColumnDef="customerAddress">
                    <th mat-header-cell *matHeaderCellDef class="small-heading"> Customer Address </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.address}} </td>
                </ng-container>

                <ng-container matColumnDef="deliveryDateTime">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Delivery Date &Time </th>
                    <td mat-cell *matCellDef="let element"> {{formatDateTime(element?.orderHistory[0].timestamp)}} </td>
                </ng-container>


                <ng-container matColumnDef="rider">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Rider </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.deliveryDetails?.deliveryPartner?.deliveryExecutive?.name}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons (page)="pageChanged($event)"
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </div>
</div>