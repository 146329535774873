import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';

@Component({
  selector: 'app-add-edit-fee-renge',
  templateUrl: './add-edit-fee-renge.component.html',
  styleUrls: ['./add-edit-fee-renge.component.scss']
})
export class AddEditFeeRengeComponent {

  hasError = false;
  deliveryexecutiveFeeSlabId: string | undefined;
  parentDeliveryFeeId: any;
  createDeliveryFeeRangeForm!: FormGroup;

  constructor(
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _executiveService: DeliveryExecutiveService,
  ) {
    const currentUrl = this._router.url;

    this._route.params.subscribe((params: Params) => {
      const id = params['id'];
      if (id) {
        if (currentUrl.includes('edit')) {
          this.deliveryexecutiveFeeSlabId = id;
          this.getDeliveryFeeSlabById();
        } else if (currentUrl.includes('create')) {
          this.parentDeliveryFeeId = id; 
        }
        this._cdr.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    const controls: { [key: string]: FormControl } = {
      charges: new FormControl('', Validators.required),
      minKm: new FormControl('', Validators.required),
      maxKm: new FormControl('', Validators.required)
    };

    if (this.deliveryexecutiveFeeSlabId) {
      controls['slabId'] = new FormControl('', Validators.required);
    }

    this.createDeliveryFeeRangeForm = new FormGroup(controls);
  }

  getDeliveryFeeSlabById() {
    if (this.deliveryexecutiveFeeSlabId) {
      this._executiveService.getDeliveryFeeRangeById(this.deliveryexecutiveFeeSlabId)
        .subscribe((response) => {
          const data = response?.data;
          if (!data) {
            this.hasError = true;
            this._snackbarService.showError('Failed to load delivery fee slab details');
            return;
          }
          const formValues: { [key: string]: any } = {
            slabId: data?.slabId, 
            charges: data?.charges,
            minKm: data?.minKm,
            maxKm: data?.maxKm
          };

          this.createDeliveryFeeRangeForm?.patchValue(formValues);
          this._cdr.markForCheck();
        }, (error: any) => {
          this._snackbarService.showError(error?.error?.message);
        });
    }
  }

  onDeliveryFeeRangeSubmit() {
    if (!this.createDeliveryFeeRangeForm.valid) {
      return;
    }

    const formData = this.createDeliveryFeeRangeForm?.value;

    if (this.deliveryexecutiveFeeSlabId) {

      this._executiveService.updateDeliveryFeeRange(this.deliveryexecutiveFeeSlabId, formData).subscribe(
        (response: any) => {
          this._snackbarService.showSuccess(response?.message);
          setTimeout(() => this.redirectToList(), 500);
        },
        (error: any) => {
          this._snackbarService.showError(error?.error?.message);
        }
      );
    } else {
      const parentDeliveryFeeId = this.parentDeliveryFeeId || localStorage?.getItem('parentDeliveryFeeId');
      const newFormData = { ...formData, slabId: parentDeliveryFeeId };

      this._executiveService.addDeliveryFeeRange(newFormData).subscribe(
        (response: any) => {
          this._snackbarService.showSuccess(response?.message);
          setTimeout(() => this.redirectToList(), 500);
        },
        (error: any) => {
          this.hasError = true;
          this._snackbarService.showError(error?.error?.message);
        }
      );
    }
  }
  redirectToList() {
    this._router.navigate(['commission-slab']);
  }
}
