<div class="main-container">
  <div class="form-header-container">
    <h3 class="heading">Personal Information</h3>
  </div>
  <div class="form-main-container">
    <div class="form-inner-container">
      <form class="form-main" [formGroup]="updateDeliveryExecutive">
        <div class="form-main-inner row">
          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Full Name</mat-label>
              <input type="text" formControlName="fullName" matInput placeholder="Enter Name" />
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('fullName')?.touched &&
                        updateDeliveryExecutive.get('fullName')?.hasError('required')
                      ">Name is required</mat-error>
            </mat-form-field>
          </div>

          <!-- <div class="col-lg-4 col-sm-4 col-xs-12 form-field" *ngIg="showEmail">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input type="text" formControlName="email" matInput placeholder="Enter Email" />
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('email')?.touched &&
                        updateDeliveryExecutive.get('email')?.hasError('required')
                      ">Email is required</mat-error>
            </mat-form-field>
          </div> -->

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Phone number</mat-label>
              <input type="text" formControlName="phone_number" matInput placeholder="Enter phone number" />
              <mat-error *ngIf="updateDeliveryExecutive.get('phone_number')?.hasError('pattern')">Phone Number must
                be 10
                digits</mat-error>
              <mat-error
                *ngIf="updateDeliveryExecutive.get('phone_number')?.touched && updateDeliveryExecutive.get('phone_number')?.hasError('required')">Phone
                Number is required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="updateDeliveryExecutive.get('dateOfBirth')?.hasError('required')">
                DOB is required
              </mat-error>
            </mat-form-field>

          </div>


          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error *ngIf="updateDeliveryExecutive.get('gender')?.hasError('required')">Gender is
                Required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State</mat-label>
              <input type="text" formControlName="state" matInput placeholder="Enter State" />
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('state')?.touched &&
                        updateDeliveryExecutive.get('state')?.hasError('required')
                      ">State is required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City</mat-label>
              <input type="text" formControlName="city" matInput placeholder="Enter City" />
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('city')?.touched &&
                        updateDeliveryExecutive.get('city')?.hasError('required')
                      ">City is required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Permanent Address</mat-label>

              <textarea type="text" formControlName="permanentAddress" matInput
                placeholder="Enter Permanent Address"></textarea>
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('permanentAddress')?.touched &&
                        updateDeliveryExecutive.get('permanentAddress')?.hasError('required')
                      ">Permanent Address is required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Temporary Address</mat-label>
              <textarea type="text" formControlName="temporaryAddress" matInput
                placeholder="Enter Temporary Address"></textarea>
              <mat-error *ngIf="
                        updateDeliveryExecutive.get('temporaryAddress')?.touched &&
                        updateDeliveryExecutive.get('temporaryAddress')?.hasError('required')
                      ">Temporary Address is required</mat-error>
            </mat-form-field>
          </div>


        </div>

        <div>
          <div class="form-header-container">
            <h3 class="heading">Identity Information</h3>
          </div>

          <div class="form-main-inner row">
            <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Aadhaar Card Number</mat-label>
                <input type="text" formControlName="aadharCardNumber" matInput placeholder="Enter Aadhaar number" />
                <mat-error
                  *ngIf="updateDeliveryExecutive.get('aadharCardNumber')?.hasError('required') && updateDeliveryExecutive.get('aadharCardNumber')?.touched">
                  Aadhaar is required
                </mat-error>
                <mat-error *ngIf="updateDeliveryExecutive.get('aadharCardNumber')?.hasError('minlength') || updateDeliveryExecutive.get('aadharCardNumber')?.hasError('maxlength')">
                  Invalid Aadhaar Number format (min length is 12)
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Pan Card Number</mat-label>
                <input type="text" formControlName="panCardNumber" matInput placeholder="Enter Pan number" />
                <mat-error *ngIf="
            updateDeliveryExecutive.get('panCardNumber')?.touched &&
            updateDeliveryExecutive.get('panCardNumber')?.hasError('required')
          ">Pan is required</mat-error>
                <mat-error *ngIf="
          updateDeliveryExecutive.get('panCardNumber')?.touched &&
          updateDeliveryExecutive.get('panCardNumber')?.hasError('pattern')
        ">Invalid PAN Number format (eg. ABCTY1234D)</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Driving License Number</mat-label>
                <input type="text" formControlName="drivingLicenseNumber" matInput
                  placeholder="Enter Driving License number" />
                <mat-error *ngIf="
            updateDeliveryExecutive.get('drivingLicenseNumber')?.touched &&
            updateDeliveryExecutive.get('drivingLicenseNumber')?.hasError('required')
            ">Driving License is required</mat-error>
                <mat-error *ngIf="
                    updateDeliveryExecutive.get('drivingLicenseNumber')?.touched &&
                    updateDeliveryExecutive.get('drivingLicenseNumber')?.hasError('pattern')
                  ">Driving License is invalid (eg. DL1420110012345)</mat-error>
              </mat-form-field>
            </div>
          </div>


        </div>

        <div class="form-main-action" fxLayoutGap="1rem">
          <button mat-raised-button class="primary-button-color" [disabled]="!validInfo()" matStepperNext
            (click)="updatePersonalInfo()">
            Save & Continue
          </button>

          <button mat-raised-button class="primary-button-color" (click)="navigateToList()">
            Back
          </button>
        </div>
      </form>
    </div>
  </div>
</div>