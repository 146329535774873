import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { ChangePasswordComponent } from 'src/app/pages/auth/change-password/change-password.component';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { cloneDeep } from 'lodash-es';
import { BellNotificationService } from 'src/app/services/pushNotification/bell-notification.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<boolean>();

  isExpanded = true;
  showDropdown = false;
  showNotifications = false;
  isChecked?: boolean;
  userData?: any;
  partnerType?: any;
  notificationCount: any;
  notifications: any;
  formattedTime!: string;
  previousNotificationCount:any;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade: AppStoreFacade,
    private _cdr: ChangeDetectorRef,
    private _notificationService: BellNotificationService,
    private datePipe: DatePipe
  ) {
    this._appStoreFacade.user$.pipe()
      .subscribe((data: any) => {
        this.userData = data;
        this._cdr.markForCheck();
      })
    this.partnerType = localStorage.getItem('partnerType');
  }

  ngOnInit(): void {
    this.isChecked = this.userData?.store_status === 'Online';
    this._notificationService.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
      this.notificationCount = notifications.length;
      if (this.notificationCount > this.previousNotificationCount) {
        this._notificationService.playSound();
      }
      this.previousNotificationCount = this.notificationCount;
      this._cdr.markForCheck();
    });
  }
  onToggleChange(event: boolean) {
    if (event === true) {
      this._authService.changeStatus(this._authService.getUserId(), 'Online').subscribe(
        res => {
          if (res.success == true) {
            const userData = this.userData ?? {};
            const updatedUserData = { ...userData, store_status: 'Online' };
            this._appStoreFacade.updateUser(updatedUserData);
            this._snackbarService.showSuccess('You are Online Now!!!');
          }
          else {
            this._snackbarService.showError(res.message)
          }
        })
    }
    else {
      this._authService.changeStatus(this._authService.getUserId(), 'Offline').subscribe(
        res => {
          if (res.success == true) {
            const userData = this.userData ?? {};
            const updatedUserData = { ...userData, store_status: 'Offline' };
            this._appStoreFacade.updateUser(updatedUserData);
            this._snackbarService.showSuccess('You are Offline Now!!!');
          }
          else {
            this._snackbarService.showError(res.message)
          }
        })
    }
  }
  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
    this.toggleSidenav.emit(this.isExpanded);
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event?.target as HTMLElement;
    const isProfileIcon = !!target.closest('.imageProfile');
    if (!isProfileIcon) {
      this.showDropdown = false;
      this.showNotifications = false;
    }
  }
  profile() {
    this.showDropdown = !this.showDropdown
  }
  onNotifications() {
    this.showNotifications = !this.showNotifications
  }
  changePassword() {
    this._router.navigate(['/auth/change-password']);
  }
  logout() {
    this._authService.clearAuthToken();
    this._router.navigate(['/auth']);
  }
  navigateTo(type: any) {
    if (type === 'myProfile') {
      this._router.navigate(['/my-account/update-profile']);
    }
    else if (type === 'businessHours') {
      this._router.navigate(['/my-account/set-business-hours']);
    }
    else if (type.message.includes('New order received')) {
      this._router.navigate([`/order-management/manage-order-list/${type?.orderDetails?.orderID}`]);
    }
    else if (type.message.includes('Consider restocking')) {
      this._router.navigate(['/product-catalogue/manage-pricing-and-inventory']);
    }
  }
  // deleteNotification(index?: number) {
  //   this._notificationService.deleteNotification({index:index}).subscribe((response:any) => {
  //       this._snackbarService.showSuccess(response?.message)
  //   });
  // }

  deleteNotification(index: number): void {
  const notification = this.notifications[index];
  const notificationID = notification?.timeStamp; // Use timeStamp for all notifications

  this._notificationService.deleteNotification(notificationID).subscribe((response: any) => {
    this._snackbarService.showSuccess(response?.message);

    // Remove from local list
    this.notifications.splice(index, 1);
    this.notificationCount = this.notifications.length;
    this._cdr.markForCheck();
  });
}


  clearAllOrder() {
    this._notificationService.clearAllOrder().subscribe((response:any) => {
      this._snackbarService.showSuccess(response?.message)
      this.notifications = [];
      this.notificationCount = 0;
      this._cdr.markForCheck();
    });
  }
}
