import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service'; // Import your service here
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Component({
  selector: 'app-delivery-executive-step-four',
  templateUrl: './delivery-executive-step-four.component.html',
  styleUrls: ['./delivery-executive-step-four.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryExecutiveStepFourComponent implements OnInit {
  executiveId: any;
  confirmationOfTrainingCompletion = false;
  agreeToMeetMetricsAndTimelines = false;

  constructor(
    private _snackBarService: SnackbarService, 
    private _route:ActivatedRoute,
    private _router:Router,
    private _executiveService: DeliveryExecutiveService,
    private _cdr: ChangeDetectorRef,
    private _appStoreFacade:AppStoreFacade
  ) {
    this._route.params.subscribe((params: Params) => {
      this.executiveId = params['id'];
      this._cdr.markForCheck();
    });
  }

  ngOnInit() {
  }


  allCheckboxesChecked(): boolean {
    return this.confirmationOfTrainingCompletion && this.agreeToMeetMetricsAndTimelines;
  }

  updateTrainingAcknowledgement() {
    let data = {
      confirmationOfTrainingCompletion: this.confirmationOfTrainingCompletion,
      agreeToMeetMetricsAndTimelines: this.agreeToMeetMetricsAndTimelines,
    };
    this._appStoreFacade.updateLoading(true);
    this._executiveService.updateTrainingAcknowledgement(this.executiveId, data).subscribe(
      (response: any) => {
        this._appStoreFacade.updateLoading(false);
        if (response?.success) {
          this._appStoreFacade.updateLoading(false);
          this._snackBarService.showSuccess(response?.message);
          this._router.navigate(['/dashboard'])
          this._cdr.markForCheck();
          this._router.navigate(['/delivery-executive/executive-list'])
        } else {
          this._appStoreFacade.updateLoading(false);
          this._snackBarService.showError(response?.error);
        }
      },
      (error: any) => {
        this._appStoreFacade.updateLoading(false);
        this._snackBarService.showError(error?.message);
        this._cdr.markForCheck();
      }
    );
  }

 
}