import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { CommonService } from 'src/app/services/common/common.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';

@Component({
  selector: 'app-add-edit-commission-slab',
  templateUrl: './add-edit-commission-slab.component.html',
  styleUrls: ['./add-edit-commission-slab.component.scss']
})
export class AddEditCommissionSlabComponent {

  commissionSlabId: any;
  isActive = true; // Initial status
  createCommissionForm!: FormGroup;
  allCities: any = [];
  allStates: any = [];

  constructor(
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _commonService: CommonService,
    private _executiveService: DeliveryExecutiveService,
  ){
    this._route.params.subscribe((params: any) => {
      this.getStates();
      const id = params['id'];
      if (id) {
        this.commissionSlabId = params['id'];
        this.getCommissionSlabById();
        this._cdr.markForCheck();
      }
    });

  }

  ngOnInit(): void {
    this.createCommissionForm = new FormGroup({
      slabName: new FormControl('', Validators.required),
      applicable: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('',Validators.required)
    });
   
  }

  onCommissionSlabSubmit(){
    if(!this.createCommissionForm.valid){
      return
    } else {
      const slabName = this.createCommissionForm?.get('slabName')!.value;
      const applicable = this.createCommissionForm?.get('applicable')!.value;
      const state = this.createCommissionForm?.get('state')!.value;
      const city = this.createCommissionForm?.get('city')!.value
      const status = this.isActive;

      const commissionSlabObject = {
        slabName,
        applicable,
        state,
        city,
        status
      };
      if(this.commissionSlabId){
        this._executiveService.updateCommissionSlab(this.commissionSlabId, commissionSlabObject).subscribe(
          (response: any) => {
            this._snackbarService.showSuccess(response?.message);
            this.redirectToList()
          },
          (error: any) => {
            this._snackbarService.showError(error?.error?.message);
          }
        );
      } else {
        this._executiveService.addCommissionSlab(commissionSlabObject).subscribe(
          (response: any) => {
            this._snackbarService.showSuccess(response?.message);
            this.redirectToList()
          },
          (error: any) => {
            this._snackbarService.showError(error?.error?.message);
          }
        );
      }
    }
  }

  getCommissionSlabById() {
    this._executiveService.getCommissionSlabById(this.commissionSlabId)
      .subscribe((response: any) => {
        let commissionSlabData = response;
        this.fetchCitiesByState(commissionSlabData?.data?.state);
        this.createCommissionForm?.setValue({
          slabName: commissionSlabData?.data?.slabName,
          applicable: commissionSlabData?.data?.applicable,
          state: commissionSlabData?.data?.state,
          city: commissionSlabData?.data?.city
        });
        this.isActive = commissionSlabData?.data?.status;
        this._cdr.markForCheck();
      });    
      this._cdr.markForCheck();
  }


  redirectToList() {
    this._router.navigate(['/commission-slab']);
  }
  getStates() {
    this._commonService.getStates().subscribe(
      (response: any) => {
        this.allStates = response?.data;
        this._snackbarService.showSuccess(response?.message);
        this._cdr.markForCheck();
      },
      (error: any) => {
        this._snackbarService.showErrorAlert('Oops!', error?.error?.message);
        this._cdr.markForCheck();
    });
  }

  fetchCitiesByState(state_id: string){
    if(!!state_id){
      this._commonService.getCities(state_id).subscribe(
        (response: any) => {
          this.allCities = response?.cities;
          this._snackbarService.showSuccess(response?.message);
          this._cdr.markForCheck();
        },
        (error: any) => {
          this._snackbarService.showErrorAlert('Oops!', error?.error?.message);
          this._cdr.markForCheck();
      });
    }
  }
}