import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {

  constructor(private _route: Router){}

  ngOnInit(): void {
  }

  @ViewChild('sidenav') sidenav: any;

  category = false;
  brand = false;
  partner = false;
  forms = false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isExpanded2 = true;
  showSubmenu2: boolean = false;
  isShowing2 = false;
  showSubSubMenu2: boolean = false;


  categoryCheckIn() {
    this.category = true;
  }
  brandCheckIn() {
    this.brand = true;
  }
  partnerCheckIn() {
    this.partner = true;
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  mouseenter2() {
    if (!this.isExpanded2) {
      this.isShowing2 = true;
    }
  }

  mouseleave2() {
    if (!this.isExpanded2) {
      this.isShowing2 = false;
    }
  }

}
