import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  open: any;

  constructor(private snackBar: MatSnackBar) { }
  showErrorAlert(title: string, text?: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
    });
  }
  // Display a success snackbar
  showSuccess(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: ['success-snackbar']
    });
  }

  // Display an error snackbar
  showError(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }

  // Display a custom snackbar
  showSnackbar(message: string, action: string = 'Close', duration: number = 3000): void {
    this.snackBar.open(message, action, { duration });
  }

  confirmStatusChange(newStatus: boolean): Promise<any> {
    const text = newStatus ? 'Are you Sure, You Want To Disable the Status ?' : 'You want to disable the status?';
    return Swal.fire({
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: newStatus ? 'Confirm' : 'Yes, disable it!',
      cancelButtonText: 'Cancel',
    });
  }
  
}