import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {  Inject } from '@angular/core';


@Component({
  selector: 'app-tracking-popup',
  templateUrl: './tracking-popup.component.html',
  styleUrls: ['./tracking-popup.component.scss']
})
export class TrackingPopupComponent implements OnInit {
  trackingOrderId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trackingOrder: any,

    public dialogRef: MatDialogRef<TrackingPopupComponent>
  ) {
  }

  ngOnInit(): void {

  }

  formatDate(timestamp: string): string {
    const date = new Date(timestamp);
    return `${date.getDate()} ${this.getMonthName(date.getMonth())}`;
  }

  formatTime(timestamp: string): string {
    const date = new Date(timestamp);
    return `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  }

  getMonthName(monthIndex: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
  }
}
