import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/messaging';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
export class NotificationModel {
  "to": string
  "notification": {
    "title": string,
    "body": string,
    "icon": string,
    "sound": string // Your sound file here
  }
}
@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  message: any = null;
  deviceToken: string | null = null;
  apiKey = environment.API_OUMS_URL;
  vapidKey = 'BP1aPmrsjZ5h6E9Imo4CpuvLHHhZpevHPOcH79num0gOKdbU9utyYPR_6jP2CLrAwolSxTKbi9zBJiGGzRHX1Qk';
  notificationSound: HTMLAudioElement = new Audio('./../../../assets/Notification/livechat-129007.mp3');
  constructor(
    private afMessaging: AngularFireMessaging,
    private _appStoreFacade: AppStoreFacade,
    private _http: HttpClient,
  ) {
    initializeApp(environment.firebase);
  }
  async requestPermission() {
    const messaging = getMessaging();
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      getToken(messaging, { vapidKey: this.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            this.deviceToken = currentToken;
            localStorage.setItem("deviceToken", currentToken)
          }
          else {
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    } else { }
  }
  pushNotification(data: any) {
    return this._http.post(`${this.apiKey}/push/notification`, data);
  }
  listenForMessages() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (Notification.permission === 'granted') {
        const { title, body } = payload.notification;
        const notification = new Notification(title, {
          body,
          icon: './../../../assets/images/fashquik_logo.svg',
        });
        // if (document.hasFocus()) {
          this.notificationSound.play().catch((error) => {
            console.error("Error playing notification sound:", error);
          });
        // }

        notification.onclick = () => {
          window.open('https://web.stg.fashquik.com/', '_blank');
        };
      }
    });
  }
}
