import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { DatePipe, formatDate } from '@angular/common';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Component({
  selector: 'app-delivery-executive-step-one',
  templateUrl: './delivery-executive-step-one.component.html',
  styleUrls: ['./delivery-executive-step-one.component.scss'],
  providers: [{ provide: DatePipe }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryExecutiveStepOneComponent implements OnInit {
  updateDeliveryExecutive!: FormGroup;
  vendorCurrentStep: number | undefined;
  executiveId: any
  isEdit = false;
  showEmail = true;
  isFormPrefilled=false;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private datePipe: DatePipe,
    private _route: ActivatedRoute,
    private _deliveryExecutiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade:AppStoreFacade
  ) {

    this.updateDeliveryExecutive = new FormGroup({
      fullName: new FormControl({ value: '', disabled: false }, [Validators.required,Validators.maxLength(50)]),
      phone_number: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{10}$')]),
      // email: new FormControl({ value: '', disabled: false }, [Validators.required,Validators.email]),
      gender: new FormControl({ value: '', disabled: false }, Validators.required),
      state: new FormControl({ value: '', disabled: false }, Validators.required),
      dateOfBirth: new FormControl({ value: '', disabled: false }, Validators.required),
      city: new FormControl({ value: '', disabled: false }, Validators.required),
      permanentAddress: new FormControl({ value: '', disabled: false }, Validators.required),
      temporaryAddress: new FormControl({ value: '', disabled: false }, Validators.required),
      aadharCardNumber: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      panCardNumber: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i),]),
      drivingLicenseNumber: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(/^[A-Za-z]{2}\d{13}$/)]),
    });
    this._route.params.subscribe((params: any) => {
      const id = params['id'];
      if (id) {
        this.isEdit = true;
      }
      
      
      this._cdr.markForCheck();
    });
  }
  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      this.executiveId = params['id'];

      if (this.executiveId) {
        this.getExecutiveById();
        this.showEmail = false
      }
    });
  }
  getExecutiveById() {
    this._appStoreFacade.updateLoading(true);
    this._deliveryExecutiveService.getExecutiveById(this.executiveId)
      .subscribe((response: any) => {
        this._appStoreFacade.updateLoading(false);
        let executiveData = response;
        let rawJoiningDate =
          executiveData?.data?.personalInformation?.dateOfBirth;
        this._deliveryExecutiveService.getExecutiveById(this.executiveId)
          .subscribe((response: any) => {
            this._appStoreFacade.updateLoading(false);
            let executiveData = response;
            let rawDateOfBirth =
              executiveData?.data?.personalInformation?.dateOfBirth;
            let formattedDateOfBirth = '';

            if (rawDateOfBirth) {
              let parts = rawDateOfBirth.split('/');
              if (parts.length === 3) {
                let formattedDate = parts[1] + '/' + parts[0] + '/' + parts[2];
                let parsedDate = new Date(formattedDate);
                if (!isNaN(parsedDate.getTime())) {
                  formattedDateOfBirth = formatDate(parsedDate, 'yyyy-MM-dd', 'en-US');
                }
              }
            }
            this.updateDeliveryExecutive.setValue({
              fullName: executiveData?.data?.personalInformation?.fullName || "",
              phone_number: executiveData?.data?.personalInformation?.phone_number || "",
              dateOfBirth: formattedDateOfBirth || "",
              gender: executiveData?.data?.personalInformation?.gender || "",
              state: executiveData?.data?.personalInformation?.state || "",
              city: executiveData?.data?.personalInformation?.city || "",
              permanentAddress: executiveData?.data?.personalInformation?.permanentAddress || "",
              temporaryAddress: executiveData?.data?.personalInformation?.temporaryAddress || "",
              aadharCardNumber: executiveData?.data?.identityInformation?.aadharCardNumber || "",
              drivingLicenseNumber: executiveData?.data?.identityInformation?.drivingLicenseNumber || "",
              panCardNumber: executiveData?.data?.identityInformation?.panCardNumber || "",
            });
            this.disablePrefilledFields();
            this._cdr.markForCheck();
          });
      });
  }
  disablePrefilledFields() {
    Object.keys(this.updateDeliveryExecutive.controls).forEach(key => {
      const control = this.updateDeliveryExecutive.get(key);
      if (control?.value) {
        control.disable();
      }
    });

    this.isFormPrefilled = true;
  }
  updateExecutiveCurrentStep(stepIndex: number) {
    this.vendorCurrentStep = stepIndex;
    localStorage.setItem('vendorCurrentStep', stepIndex?.toString());
    this._cdr.markForCheck();
  }
  formatDateValue(dateValue: any): string {
    const date = new Date(dateValue);
    if (isNaN(date?.getTime())) {
      return '';
    }
    return this.datePipe?.transform(date, 'dd/MM/yyyy') || '';
  }
  navigateToList() {
    this._router.navigate(['/delivery-executive/executive-list'])
  }
  updatePersonalInfo() {
    const fullName = this.updateDeliveryExecutive?.get('fullName')?.value;
    // const email = this.updateDeliveryExecutive.get('email')?.value;
    const phone_number = this.updateDeliveryExecutive?.get('phone_number')?.value;
    const DOB = this.updateDeliveryExecutive?.get('dateOfBirth')?.value;
    const dateOfBirth = this.formatDateValue(DOB);
    const gender = this.updateDeliveryExecutive?.get('gender')?.value;
    const state = this.updateDeliveryExecutive?.get('state')?.value;
    const city = this.updateDeliveryExecutive?.get('city')?.value;
    const permanentAddress = this.updateDeliveryExecutive?.get('permanentAddress')?.value;
    const temporaryAddress = this.updateDeliveryExecutive?.get('temporaryAddress')?.value;
    const panCardNumber = this.updateDeliveryExecutive?.get('panCardNumber')?.value;
    const aadharCardNumber = this.updateDeliveryExecutive?.get('aadharCardNumber')?.value;
    const drivingLicenseNumber = this.updateDeliveryExecutive?.get('drivingLicenseNumber')?.value;

    let updateExecutiveObject = {
      fullName: fullName,
      // email: email,
      dateOfBirth: dateOfBirth,
      phone_number: phone_number,
      gender: gender,
      state: state,
      city: city,
      permanentAddress: permanentAddress,
      temporaryAddress: temporaryAddress,
      panCardNumber: panCardNumber,
      drivingLicenseNumber: drivingLicenseNumber,
      aadharCardNumber: aadharCardNumber

    };
    this._appStoreFacade.updateLoading(true);
    if (this.updateDeliveryExecutive?.valid) {
      this._deliveryExecutiveService.updatePersonalInformation(this.executiveId, updateExecutiveObject).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          if (response?.success) {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showSuccess(response?.message);
            this._cdr.markForCheck();
          }
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError(error?.error?.message);
        }
      );
    }
  }
  validInfo() {
    return this.updateDeliveryExecutive.valid;
  }
}
