import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../delivery-executive.service';
import { SweetAlertService } from 'src/app/services/common/sweet-alert.service';
import { CommonService } from 'src/app/services/common/common.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';


@Component({
  selector: 'app-ongoing-task',
  templateUrl: './ongoing-task.component.html',
  styleUrls: ['./ongoing-task.component.scss']
})
export class OngoingTaskComponent {
  selectedTabIndex: number = 0;
  Current:any
  showNgTemplateContent=true;
  onTabChange(event: any): void {
    this.selectedTabIndex = event.index;
    sessionStorage.setItem('currentTab', this.selectedTabIndex.toString());
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 5;


  calculateSerialNumber(indexOnPage: number): number {
      return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any) {
      this.currentPageIndex = event.pageIndex;
  }
  displayedColumns: string[] = [
    'srno',
    'riderName',
    'mobileNo',
    'email',
    'action',
  ];
  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _swalService: SweetAlertService,
    private _commonService: CommonService,
    private _appStoreFacade:AppStoreFacade
  ){
    const savedIndex = sessionStorage.getItem('currentTab');
    this.selectedTabIndex = savedIndex ? parseInt(savedIndex, 10) : 0;
  }
  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
}

  redirectActions(action: string, id?: number) {
    if (action === 'create') {
      this._router.navigate(['/delivery-executive/add-executive']);
    } else if (action === 'edit') {
      this._router.navigate(['/delivery-executive/update-executive', id]);
    } else if (action === 'view') {
      this._router.navigate(['/partners/delivery-partner/view', id]);
    }
  }
}
