import { Action } from "@ngrx/store";
import { DeviceDetectorResult } from "device-detector-js";


export enum AppActionTypes {
    UpdateUser = '[App] Update User',
    UpdateBasicInfo='[App] Update Basic Info',
    UpdateLoaderVisibility = '[App] Update Loader Visibility',
    UpdateDeviceDetails = '[App] Update Device Details',
    Logout = '[App] Logout'
}

export class UpdateUser implements Action {
    readonly type = AppActionTypes.UpdateUser;

    constructor(public user: any){}
}
export class UpdateBasicInfo implements Action {
    readonly type = AppActionTypes.UpdateBasicInfo;

    constructor(public basicInfo: any){}
}
export class Logout implements Action {
    readonly type = AppActionTypes.Logout;

    constructor(){}
}

export class UpdateLoaderVisibility implements Action {
    readonly type = AppActionTypes.UpdateLoaderVisibility;

    constructor(public visibility: any){}
}

export class UpdateDeviceDetails implements Action {
    readonly type = AppActionTypes.UpdateDeviceDetails;

    constructor(public deviceDetails: DeviceDetectorResult){}
}

export type AppActions = 
    | UpdateUser
    | UpdateDeviceDetails
    | UpdateLoaderVisibility
    | UpdateBasicInfo
    | Logout;