import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { SweetAlertService } from 'src/app/services/common/sweet-alert.service';
import { CommonService } from 'src/app/services/common/common.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { MatDialog } from '@angular/material/dialog';
import { AssignedOrdersPopupComponent } from './assigned-orders-popup/assigned-orders-popup.component';
import { TrackingPopupComponent } from './tracking-popup/tracking-popup.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { FormControl } from '@angular/forms'; 
@Component({
  selector: 'app-ongoing-list',
  templateUrl: './ongoing-list.component.html',
  styleUrls: ['./ongoing-list.component.scss']
})
export class OngoingListComponent implements OnInit{
    assignedStatusControl = new FormControl('assigned');

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 10;
  id:any
  deliveryexecutive:any;
  deliveryPartnerId:any;
  status: any;


  calculateSerialNumber(indexOnPage: number): number {
      return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any) {
      this.currentPageIndex = event.pageIndex;
  }
  displayedColumns: string[] = [
    'srno',
    'orderId',
    'customerName',
    'customerAddress',
    'rider',
    'status',
    'tracking',           
  ];
  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _swalService: SweetAlertService,
    private _commonService: CommonService,
    private _appStoreFacade:AppStoreFacade,
    private _dialog:MatDialog,
    private _route:ActivatedRoute
  ){

    this._route.params.subscribe((params: any) => {
      if (!!params?._id) {
        this.deliveryPartnerId = params?._id;
      }
      this._cdr.markForCheck();
    });
  }
  ngOnInit(): void {
    this.loadOngoingTasks('assigned');

    this.assignedStatusControl.valueChanges.subscribe((status) => {
      return this.loadOngoingTasks(this.status);
    });
  }
  loadOngoingTasks(status: string): void {
    if (status === 'assigned') {
      this.getOngoingTaskAssigned(this.id);
    } else if (status === 'unassigned') {
      this.getOngoingTaskUnassigned(this.id);
    }
  }
  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
}
openAssignedOrder(order:any): void {
  this._dialog.open(AssignedOrdersPopupComponent, {
    data: order,
    width: '60%',
    panelClass: 'order-details-dialog',
  });
}

openTrackingDelivery(trackingOrder:any): void {
  this._dialog.open(TrackingPopupComponent, {
    data: trackingOrder,
    width: '50%',
    panelClass: 'order-details-dialog',
  });
}
openAllOrder(order: any):void{
  this._dialog.open(AllOrdersComponent, {
    data: order,
    width: '40%',
    panelClass: 'order-details-dialog',
  });
}
onStatusFilterChange(status: string): void {
  if (status === 'assigned') {
    this.getOngoingTaskAssigned(this.id)
  } else if (status === 'unassigned') {
    this.getOngoingTaskUnassigned(this.id)
  } else {
    this.loadOrders();
  }

  this.dataSource.paginator = this.paginator;
}
loadOrders(){
  const data = this.dataSource.data;

    this.dataSource.data = data;
}
getOngoingTaskAssigned(id: any) {
  this._executiveService.getOngoingTaskAssigned(id).pipe(
    tap((response: any) => {
      this._appStoreFacade.updateLoading(false);
      if (response.success) {
        this.dataSource.data = response?.data;
        this.dataSource.paginator = this.paginator;
      } else {
        this._snackbarService.showError(response?.message);
      }
      this._cdr.markForCheck();
    })
  ).subscribe(
    () => {},
    (error: any) => {
      this._snackbarService.showError(error?.error?.message);
      this._cdr.markForCheck();
    }
  );
}
getOngoingTaskUnassigned(id: any) {
  this._executiveService.getOngoingTaskUnassigned(id).pipe(
    tap((response: any) => {
      this._appStoreFacade.updateLoading(false);
      if (response.success) {
        this.dataSource.data = response?.data;
        this.dataSource.paginator = this.paginator;
      } else {
        this._snackbarService.showError(response?.message);
      }
      this._cdr.markForCheck();
    })
  ).subscribe(
    () => {},
    (error: any) => {
      this._snackbarService.showError(error?.error?.message);
      this._cdr.markForCheck();
    }
  );
}
}
