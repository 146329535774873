import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  token:any;
  isPasswordHidden = true;
  newPasswordHidden = true;
  confirmPasswordHidden = true;

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _snackBarService: SnackbarService,
    private _router: Router,
    private _authService: AuthService,
    private _cdr: ChangeDetectorRef,
    private _appStoreFacade:AppStoreFacade

  ) { }

  ngOnInit(): void {
    this.token = this._route.snapshot.queryParamMap.get('token');
    this.resetPasswordForm = this._fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required,
        this._authService.passwordValidator()
      ]],
      reenterPassword: ['', Validators.required]
    }, 
    { validators: this._authService.passwordMatchValidator()

     });
     
  }

  changePassword() {
    this._appStoreFacade.updateLoading(true);
    if (this.resetPasswordForm.valid) {
      const newPassword = this.resetPasswordForm?.get('newPassword')?.value;
      const currentPassword = this.resetPasswordForm?.get('oldPassword')?.value;

      const payLoad = {
        newPassword: newPassword,
        currentPassword: currentPassword
      };
      this._authService.changePasswordApi(payLoad, this.token).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackBarService.showSuccess(response?.message);
          this._router.navigate(['/auth/login']);
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackBarService.showError("Unable to reset your password");
        }
      );
    } else {
      this._appStoreFacade.updateLoading(false);
      this.resetPasswordForm.markAllAsTouched();
    }
  }

}
