import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild, Inject
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../../delivery-executive.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assigned-orders-popup',
  templateUrl: './assigned-orders-popup.component.html',
  styleUrls: ['./assigned-orders-popup.component.scss']
})
export class AssignedOrdersPopupComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  constructor(private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade: AppStoreFacade,
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public trackingOrder: any,

    public dialogRef: MatDialogRef<AssignedOrdersPopupComponent>) { }

  ngOnInit(): void {
    this.getAssignedRider()
  }
  getAssignedRider() {
    this._executiveService.getAssignedRider().pipe(
      tap((response: any) => {
        this._appStoreFacade.updateLoading(false)
        if (response.success) {
          this.dataSource.data = response?.data
          // this.dataSource.paginator = this.paginator;
        } else {
          this._snackbarService.showError(response?.message)
        }
        this._cdr.markForCheck();
      })
    ).subscribe(
      () => { },
      (error: any) => {
        this._snackbarService.showErrorAlert(error?.error?.message);
        this._cdr.markForCheck();
      }
    );
  }
  displayedColumns: string[] = [
    'rider',
    'vehicleDetail',
    'action',
  ];
  getAssignOrder(element: any) {
    const payload = {
      orderId: this.trackingOrder._id,
      executiveId: element._id
    }
    this._executiveService
      .getAssignOrder(payload)
      .subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          if (response?.success) {
            // this._appStoreFacade.updateUser(response?.data);
            this._snackbarService.showSuccess(response?.message);
            this._router.navigate(['/delivery-executive/ongoing-list/assign-list']);
            this._cdr.markForCheck();
          }
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError(error?.error?.message);
        }
      );
  }
}
