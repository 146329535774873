import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../delivery-executive.service';
import { SweetAlertService } from 'src/app/services/common/sweet-alert.service';
import { CommonService } from 'src/app/services/common/common.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';


@Component({
  selector: 'app-executive-list',
  templateUrl: './executive-list.component.html',
  styleUrls: ['./executive-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveListComponent implements OnInit {
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 5;

  calculateSerialNumber(indexOnPage: number): number {
      return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any) {
      this.currentPageIndex = event.pageIndex;
  }
  displayedColumns: string[] = [
    'srno',
    'employeeId',
    'fullName',
    'gender',
    'mobileNo',
    'email',
    'state',
    'city',
    'action',
  ];
  allCity:any=[]

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();


  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _swalService: SweetAlertService,
    private _commonService: CommonService,
    private _appStoreFacade:AppStoreFacade
  ) {}

  ngOnInit() {
    this.getDeliveryExecutive();
    this.fetchCityName();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = `${data.personalInformation?.fullName || ''} ${data.personalInformation?.phone_number || ''} ${data.employmentDetails?.employeeId || ''} ${this.getStateName(data.personalInformation?.state) || ''} ${this.getCityName(data.personalInformation?.city) || ''}`.toLowerCase();
      return dataStr.includes(filter);
    };
  }

  getDeliveryExecutive() {
    this._appStoreFacade.updateLoading(true);
    this._executiveService
      .getdeliveryExecutive()
      .pipe(
        tap((response: any) => {
          this._appStoreFacade.updateLoading(false);
          if (response?.success) {
            this._appStoreFacade.updateLoading(false);
            this.dataSource.data = response?.data;
            this.dataSource.paginator = this.paginator;
            
          } else {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showError(response?.message);
          }
          this._cdr.markForCheck();
        })
      )
      .subscribe(
        () => {},
        (error: any) => {
          this._cdr.markForCheck();
        }
      );
      this.getCityName(this.allCity);
  }

  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
}

  redirectActions(action: string, id?: number) {
    if (action === 'create') {
      this._router.navigate(['/delivery-executive/add-executive']);
    } else if (action === 'edit') {
      this._router.navigate(['/delivery-executive/update-executive', id]);
    } else if (action === 'view') {
      this._router.navigate(['/partners/delivery-partner/view', id]);
    }
  }
  deletedeliveryExecutive(row: any) {
    
    this._swalService.showDeleteAlert().then((result: { isConfirmed: any; }) => {
      this._appStoreFacade.updateLoading(true);
      const id = row._id;
      if (result.isConfirmed) {
        this._executiveService.deleteDeliveryExecutive(id).subscribe(
          (response: any) => {
            this._appStoreFacade.updateLoading(false);
            const index = this.dataSource.data.findIndex(
              (item: any) => item._id === id
            );
            if (index > -1) {
              this.dataSource.data.splice(index, 1);
              this.dataSource._updateChangeSubscription();
            }
            this._swalService.showDeleteSuccess();
          },
          (error: any) => {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showError(error);
          }
        );
      }
      this._appStoreFacade.updateLoading(false);
    });
  }
  getStateName(stateId: string): string {
    return this._commonService.getStateName(stateId);
  }
  getCityName(cityId:any) {
    
    const city = this.allCity.find((city: any) => city.cityId === cityId);
    return city ? city.city_name : '';
  }
  fetchCityName() {
    this._appStoreFacade.updateLoading(true);
    this._commonService.getCityName().subscribe(
      (response: any) => {
        this._appStoreFacade.updateLoading(false);
        this.allCity=response?.cities || [];
      },
      (error: any) => {
        this._appStoreFacade.updateLoading(false);
        this._snackbarService.showError(error);
      }
    );
    
  }

}
