import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Component({
  selector: 'app-delivery-executive-step-three',
  templateUrl: './delivery-executive-step-three.component.html',
  styleUrls: ['./delivery-executive-step-three.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryExecutiveStepThreeComponent implements OnInit {
  bankInformation!: FormGroup;
  vendorCurrentStep: number | undefined;
  employeeInsuranceImg: string | ArrayBuffer | null = null;
  executiveId: any;
  isImageUploaded = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _deliveryExecutiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade:AppStoreFacade
  ) {

    this.bankInformation = new FormGroup({
      bankName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),

      ]),
      accountHolderName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),

      accountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(16),
      ]),

      ifscCode: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([A-Z]{4}\d{7})$/),
      ]),
      employeeInsurance: new FormControl('',
        Validators.required)
    });

  }
  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      this.executiveId = params['id'];

      if (this.executiveId) {
        this.getExecutiveById();
      }
    });
  }
  onFileChangeEmployeeInsurance(event: any, fileType: string) {
    this.isImageUploaded = false;
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.employeeInsuranceImg = reader.result;
        this._cdr.markForCheck();
      };

    }
    this._appStoreFacade.updateLoading(true);
    if (selectedFile.type.startsWith('image/') || selectedFile.type === 'application/pdf') {
      const uploadData = new FormData();
      uploadData.append('file', selectedFile, selectedFile.name);
      this._deliveryExecutiveService.uploadImg(uploadData).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          if (response.success) {
            this._appStoreFacade.updateLoading(false);
            this.isImageUploaded = true;
            this._snackbarService.showSuccess(response?.message);
            this.employeeInsuranceImg = response?.ImageUrl;
            this._cdr.markForCheck();
          }
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError('Error uploading');
          this._cdr.markForCheck();
        }
      );
    } else {
      this._appStoreFacade.updateLoading(false);
      this._snackbarService.showError('Unsupported file type. Please select an image or PDF file.');
      this._cdr.markForCheck();
    }
  }

  getExecutiveById() {
    this._appStoreFacade.updateLoading(true);
    this._deliveryExecutiveService.getExecutiveById(this.executiveId)
      .subscribe((response: any) => {
        this._appStoreFacade.updateLoading(false);
        const executiveData = response?.data;
        const bankingInformation = executiveData?.bankingInformation;
        const insuranceDetails = executiveData?.insuranceDetails;
  
        this.employeeInsuranceImg = insuranceDetails?.employeeInsurance || null;
  
        this.bankInformation.setValue({
          bankName: bankingInformation?.bankName || "",
          accountHolderName: bankingInformation?.accountHolderName || "",
          accountNumber: bankingInformation?.accountNumber || "",
          ifscCode: bankingInformation?.ifscCode || "",
          employeeInsurance: this.employeeInsuranceImg || ""
        });
  
        this._cdr.markForCheck();
      });
  }

  updateExecutiveCurrentStep(stepIndex: number) {
    this.vendorCurrentStep = stepIndex;
    localStorage.setItem('vendorCurrentStep', stepIndex?.toString());
    this._cdr.markForCheck();
  }

  updateBankInfo() {
    const bankName = this.bankInformation?.get('bankName')?.value;
    const accountHolderName = this.bankInformation?.get('accountHolderName')?.value;
    const accountNumber = this.bankInformation?.get('accountNumber')?.value;
    const ifscCode = this.bankInformation?.get('ifscCode')?.value;
    const employeeInsurance = this.employeeInsuranceImg;

    let updateExecutiveObject = {
      bankName: bankName,
      accountHolderName: accountHolderName,
      accountNumber: accountNumber,
      ifscCode: ifscCode,
      employeeInsurance: employeeInsurance,

    };
    this._appStoreFacade.updateLoading(true);
    if (this.bankInformation?.valid) {
      this._deliveryExecutiveService.updateBankingInformation(this.executiveId, updateExecutiveObject).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          if (response?.success) {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showSuccess(response?.message);
            this._cdr.markForCheck();
          }
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError(error?.error?.message);
          this._cdr.markForCheck();
        }
      );
    }
  }
  validInfo() {
    return this.bankInformation.valid;
  }
}
