
<div class="main-container">
    <div class="table-search-container  d-flex flex-wrap align-items-center justify-space-between w-100">
        <div class="table-search-inner col-12 col-md-6 col-lg-5">
          <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search" class="search-input">
        </div>
        <div class="assign-search-inner col-12 col-md-3 col-lg-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Select Status</mat-label>
            <mat-select [formControl]="assignedStatusControl" required (selectionChange)="onStatusFilterChange($event.value)">
              <mat-option value="assigned">Assigned</mat-option>
              <mat-option value="unassigned">Unassigned</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    
      </div>
      <div class="table-main-container">
        <div class="table-inner-container">
          <table mat-table [dataSource]="dataSource">              
            <ng-container matColumnDef="srno">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
              <td mat-cell *matCellDef="let element; let i = index"> {{ calculateSerialNumber(i) }} </td>
            </ng-container>

            <ng-container matColumnDef="orderId">
              <th mat-header-cell *matHeaderCellDef class="small-heading">Order Id</th>
              <td mat-cell *matCellDef="let element" (click)="openAllOrder(element)"> {{element?.orderID}} </td>
            </ng-container>

            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef class="small-heading">Customer Name </th>
                <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="customerAddress">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Customer Address </th>
              <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.address}} </td>
          </ng-container>

    
          <ng-container matColumnDef="rider">
            <th mat-header-cell *matHeaderCellDef class="small-heading">Rider </th>
            <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails?.deliveryPartner?.deliveryExecutive?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status" >
          <th mat-header-cell *matHeaderCellDef class="small-heading">Status</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element?.assigned" class="p-1">Assigned</span>
            <span *ngIf="!element?.assigned" class="shadow p-1 border-1 clickable " 
            (click)="element?.orderStatus === 'Pending' && openAssignedOrder(element)"
            >
            {{element?.orderStatus === 'Pending' ? "+Assigned":"Assigned"}}
              
            </span>
          </td>
        </ng-container>

          <ng-container matColumnDef="tracking">
            <th mat-header-cell *matHeaderCellDef class="small-heading">Tracking</th>
            <td mat-cell *matCellDef="let element" (click)="openTrackingDelivery(element)">
                <span  class="shadow p-1 border-1">Details</span>
            </td>
          </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
          <mat-paginator [pageSizeOptions]="[10,25,50]" 
                         showFirstLastButtons
                         (page)="pageChanged($event)"
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
      </div>
</div>

