import { DeviceDetectorResult } from "device-detector-js";
import { AppActionTypes, AppActions } from "./app.actions";


export const appFeatureKey = 'app';

export interface AppState {
    user: any;
    basicInfo:any;
    userToken: any;
    isLoading: boolean;
    deviceDetails: DeviceDetectorResult;
}

const logoutState: AppState = {
    userToken: null,
    user: {},
    isLoading: false,
    basicInfo:{},
    deviceDetails: {
        client: {
            type: '',
            name: '',
            version: '',
            engine: '',
            engineVersion: ''
        },
        os: {
            name: '',
            version: '',
            platform: ''
        },
        device: {
            type: '',
            brand: '',
            model: ''
        },
        bot: null
    }
}

export const appInitialState : AppState = {
    userToken: null,
    user: {},
    basicInfo:{},
    isLoading: false,
    deviceDetails: {
        client: {
            type: '',
            name: '',
            version: '',
            engine: '',
            engineVersion: ''
        },
        os: {
            name: '',
            version: '',
            platform: ''
        },
        device: {
            type: '',
            brand: '',
            model: ''
        },
        bot: null
    }
}

export function appReducer(
    state: AppState = appInitialState,
    action: AppActions
): AppState {
    switch (action.type){
        case AppActionTypes.UpdateUser: {
            return {
                ...state,
                user: action.user || null
            }
        }

        case AppActionTypes.UpdateLoaderVisibility: {
            return {
                ...state,
                isLoading: action.visibility
            }
        }

        case AppActionTypes.UpdateBasicInfo: {
            return {
                ...state,
                basicInfo: action.basicInfo || null
            }
        }

        case AppActionTypes.UpdateDeviceDetails: {
            return {
                ...state,
                deviceDetails: action.deviceDetails
            }
        }

        case AppActionTypes.Logout: {
            const { deviceDetails, ...logout } = 
            logoutState;
            return {
                ...state,
                ...logout,
            };
        }

        default:
            return state;
    }
}