import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { DatePipe, formatDate } from '@angular/common';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Component({
  selector: 'app-delivery-executive-step-two',
  templateUrl: './delivery-executive-step-two.component.html',
  styleUrls: ['./delivery-executive-step-two.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryExecutiveStepTwoComponent implements OnInit {
  employmentDetails!: FormGroup;
  vendorCurrentStep: number | undefined;
  isEdit = false;
  executiveId: any;
  isImageUploaded = false;
  vehicleIns: '' | any;
  vehicleInsurance : string | ArrayBuffer | null = null;

  constructor(
    private _cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _route: ActivatedRoute,
    private _deliveryExecutiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade:AppStoreFacade

  ) {
    this.employmentDetails = new FormGroup({
      employeeId: new FormControl('', [Validators.required,Validators.maxLength(10)]),
      designation: new FormControl('', [Validators.required,Validators.minLength(5),Validators.maxLength(50)]),
      joiningDate: new FormControl('', [Validators.required]),
      vehicleType: new FormControl('', [Validators.required]),
      vehicleRegistrationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/),
      ]),
      vehicleInsurance: new FormControl('', [Validators.required]),
    });
    this._route.params.subscribe((params: any) => {
      const id = params['id'];
      if (id) {
        this.isEdit = true;
      }
      this._cdr.markForCheck();
    });
  }
  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      this.executiveId = params['id'];
      if (this.executiveId) {
        this.getExecutiveById();
      }
    });
  }
  formatDateValue(dateValue: any): string {
    const date = new Date(dateValue);
    if (isNaN(date?.getTime())) {
      return '';
    }
    return this.datePipe?.transform(date, 'dd/MM/yyyy') || '';
  }
  getExecutiveById() {
    this._appStoreFacade.updateLoading(true);
    this._deliveryExecutiveService
      .getExecutiveById(this.executiveId)
      .subscribe((response: any) => {
        this._appStoreFacade.updateLoading(false);
        let executiveData = response;
        let rawJoiningDate =
          executiveData?.data?.employmentDetails?.joiningDate;
         this.vehicleInsurance = executiveData?.data?.vehicleInformation?.vehicleInsurance || null;
        let parsedDate = new Date(rawJoiningDate);
        let formattedDate = formatDate(parsedDate, 'yyyy-MM-dd', 'en-US');

        this.employmentDetails.setValue({
          employeeId: executiveData?.data?.employmentDetails?.employeeId || '',
          designation:
            executiveData?.data?.employmentDetails?.designation || '',
          joiningDate: formattedDate || '',
          vehicleType:
            executiveData?.data?.vehicleInformation?.vehicleType || '',
          vehicleRegistrationNumber:
            executiveData?.data?.vehicleInformation
              ?.vehicleRegistrationNumber || '',
          vehicleInsurance:
            this.vehicleInsurance || '',
            
        });
        
        this._cdr.markForCheck();
      });
  }
  onFileChangeForVehicleInsurance(event: any, fileType: string) {
    this.isImageUploaded = false
    const selectedFile = event?.target?.files[0];
    const reader = new FileReader();
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const file = event?.target?.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.vehicleInsurance = reader?.result;
        this._cdr.markForCheck();
      };
    }
    this._appStoreFacade.updateLoading(true);
    if (
      selectedFile.type.startsWith('image/') ||
      selectedFile.type === 'application/pdf'
    ) {
      const uploadData = new FormData();
      uploadData.append('file', selectedFile, selectedFile.name);
      this._deliveryExecutiveService.uploadImg(uploadData).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          this.isImageUploaded = true;
          this._snackbarService.showSuccess(response?.message);
          this.vehicleIns = response?.ImageUrl;
          this._cdr.markForCheck();
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError('Error uploading');
          this._cdr.markForCheck();
        }
      );
    } else {
      this._appStoreFacade.updateLoading(false);
      this._snackbarService.showError(
        'Unsupported file type. Please select an image or PDF file.'
      );
      this._cdr.markForCheck();
    }
  }

  updateEmploymentDetails() {
    const employeeId = this.employmentDetails?.get('employeeId')?.value;
    const designation = this.employmentDetails?.get('designation')?.value;
    const jd = this.employmentDetails?.get('joiningDate')?.value;
    const joiningDate = this.formatDateValue(jd);
    const vehicleType = this.employmentDetails?.get('vehicleType')?.value;
    const vehicleRegistrationNumber = this.employmentDetails?.get(
      'vehicleRegistrationNumber'
    )?.value;
    const vehicleInsurance = this.vehicleIns;
    let updateExecutiveObject = {
      employeeId: employeeId,
      designation: designation,
      joiningDate: joiningDate,
      vehicleType: vehicleType,
      vehicleRegistrationNumber: vehicleRegistrationNumber,
      vehicleInsurance: vehicleInsurance,
    };
    
    if (this.employmentDetails?.valid) {
      this._appStoreFacade.updateLoading(true);
      this._deliveryExecutiveService
        .updateEmploymentDetails(this.executiveId, updateExecutiveObject)
        .subscribe(
          (response: any) => {
            this._appStoreFacade.updateLoading(false);
            if (response?.success) {
              this._appStoreFacade.updateLoading(false);
              this._snackbarService.showSuccess(response?.message);
              this._cdr.markForCheck();
            }
          },
          (error: any) => {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showError(error?.error?.message);
            this._cdr.markForCheck();
          }
        );
    }
  }

  validInfo() {
    return this.employmentDetails.valid;
  }
}
