<div mat-dialog-content>
    <div class="tracking-popup">
        <div class="m-1 border-b">
            <h2 class="m-1">Tracking</h2>
            <span class="tracking-date m-1 pb-1">{{ formatDate(trackingOrder?.orderHistory[0]?.timestamp) }}</span>
        </div>
      
  
      <div class="tracking-timeline p-2">
        <div *ngFor="let history of trackingOrder?.orderHistory; let i = index" class="timeline-item">
          
          <div class="timeline-content">
            <div class="timeline-date p-1">{{ formatDate(history.timestamp) }}</div>
            <div class="timeline-circle"></div>
            <div class="timeline-box">
              <p><strong>Order ID:</strong> {{ trackingOrder?.orderID }}</p>
              <p><strong>Time:</strong> {{ formatTime(history.timestamp) }}</p>
              <p><strong>Task status:</strong> {{ history.status }}</p>
              <p *ngIf="history.description">{{ history.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  