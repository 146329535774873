import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { AllOrdersComponent } from '../ongoing-list/all-orders/all-orders.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 5;
  selectedTimeRange: string = 'last7days';
  fromDate!: Date;
  toDate!: Date;
  dateRangeError: boolean = false;
  calculateSerialNumber(indexOnPage: number): number {
    return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any) {
    this.currentPageIndex = event.pageIndex;
  }
  displayedColumns: string[] = [
    'srno',
    'orderId',
    'customerName',
    'customerAddress',
    'deliveryDateTime',
    'rider',
  ];
  constructor(
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade: AppStoreFacade,
    private _dialog: MatDialog,
  ) { }
  ngOnInit(): void {
    this.getCompleteOrder();
  }
  onTimeRangeChange(): void {
    this.dateRangeError = false;
    if (this.selectedTimeRange !== 'custom') {
      this.getCompleteOrder();
    }
  }
  applyCustomDateRange(): void {
    console.log('fromDate:', this.fromDate, 'toDate:', this.toDate);

    if (!this.fromDate || !this.toDate) {
      this.dateRangeError = true;
      return;
    }
    this.dateRangeError = false;
    this.getCompleteOrder();
  }

  getCompleteOrder() {
    const params: any = {
      timeRange: this.selectedTimeRange,
      page: this.currentPageIndex + 1,
      limit: this.pageSize,

    };

    if (this.selectedTimeRange === 'custom' && this.fromDate && this.toDate) {
      params.fromDate = this.formatDateForAPI(this.fromDate);
      params.toDate = this.formatDateForAPI(this.toDate);
    }
    this._executiveService.getCompleteOrder(this.selectedTimeRange, this.currentPageIndex + 1, this.pageSize, this.fromDate, this.toDate).pipe(
      tap((response: any) => {
        this._appStoreFacade.updateLoading(false)
        if (response.success) {
          this.dataSource.data = response?.data
          this.dataSource.paginator = this.paginator;

        } else {
          this._snackbarService.showError(response?.message)
        }
        this._cdr.markForCheck();
      })
    ).subscribe(
      () => { },
      (error: any) => {
        this._snackbarService.showErrorAlert(error?.error?.message);
        this._cdr.markForCheck();
      }
    );
  }
  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
  }
  openAllOrder(order: any): void {
    this._dialog.open(AllOrdersComponent, {
      data: order,
      width: '40%',
      panelClass: 'order-details-dialog',
    });
  }

  formatDateTime(dateTimeString: any) {
    if (!dateTimeString) {
      return '';
    }

    const date = new Date(dateTimeString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
  }
  formatDateForAPI(date: Date): string {
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

}
