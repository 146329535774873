import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState, appFeatureKey } from "./app.reducer";
import { get } from "lodash-es";

const getAppState = createFeatureSelector<AppState>(appFeatureKey);

export const getUser = createSelector(getAppState, (state:any) => 
    get(state, 'user')
);

export const getLoading = createSelector(getAppState, (state:any) => 
    get(state, 'isLoading')
);
export const getBasicInfo = createSelector(getAppState, (state:any) => 
    get(state, 'basicInfo')
);
export const getDeviceDetails = createSelector(getAppState, (state:any) => 
    get(state, 'deviceDetails')
);

export const appQuery = {
    getUser,
    getLoading,
    getBasicInfo,
    getDeviceDetails
}