import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DeliveryExecutiveService implements OnInit{
  
  // apiUrl = "http://pms-api.stg.fashquik.com";
  apiUrl = environment.API_URL;
  oumsApiUrl=environment.API_OUMS_URL;
  endpointsForDeliveryExecutive = {
    get: "/deliveryExecutive",
    getPersonalInfo: "/deliveryExecutive/personal-information",
    getBankinginfo: "/deliveryExecutive/banking-information",
    getEmploymentInfo: "/deliveryExecutive/employment-details",
    getTrainingAcknowledgement: "/deliveryExecutive/training-acknowledgment",
    uploadImage: "/upload",
    getCommissionSlab: "/core/delivery-partner-fee",
    getDeliveryFee: "/core/delivery-partner-fee-range",
    riderList:"/delivery-partner/rider-list",
    ongoingTask:'/app/delivery/order/delivery-partner/',
    completeOrder:'/app/delivery/order/completed-order/',
    assignedRider:'/app/delivery/order/rider-no-task/',
    assignOrder:'/app/delivery/order/assign-order',
    riderStatus: '/core/delivery-partner'
  }
  
  constructor(private http: HttpClient,private _executiveService :AuthService, private _authService: AuthService) { }
  
  getDeliveryPartnerId(): string | null {
    return this._authService.getUserId();
  }
  
  getdeliveryExecutive(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}?isDeleted=false`, { 
      headers: this._executiveService.getHeaders()
     });
  }
  getExecutiveById(id:any): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}/${id}`, { 
      headers: this._executiveService.getHeaders()
      
     });
  }
  deleteDeliveryExecutive(id: string):Observable<any> {
    
    return this.http.delete<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}/${id}` ,{ 
      headers: this._executiveService.getHeaders()
     });
  }
  addDeliveryExecutive(data:any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.get}`,data ,{ 
      headers: this._executiveService.getHeaders()
     });
  }
 
  updatePersonalInformation(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getPersonalInfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateEmploymentDetails(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getEmploymentInfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateBankingInformation(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getBankinginfo}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  updateTrainingAcknowledgement(id: any, data: any):Observable<any>{
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getTrainingAcknowledgement}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  uploadImg(data:any){
    return this.http.post(`${this.apiUrl}${this.endpointsForDeliveryExecutive.uploadImage}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  
  getCommissionSlab(pageNumber: number, pageSize: number): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}`, {
      headers: this._executiveService.getHeaders()
    });
  }
  deleteCommissionSlab(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}/${id}`, {
      headers: this._executiveService.getHeaders()
    });
  }
  addCommissionSlab(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}`, data, {
      headers: this._executiveService.getHeaders()
    });
  }
  

  getCommissionSlabById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}/${id}`);
  }

  updateCommissionSlab(id: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}/${id}`, data, {
      headers: this._executiveService.getHeaders()
    });
  }
  ngOnInit(): void {
  }
  deleteDeliveryFeeRange(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getCommissionSlab}/${id}`, {
      headers: this._executiveService.getHeaders()
    });
  }
  getDeliveryFee(slabId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getDeliveryFee}?page=1&limit=20&slabId=${slabId}`, {
      headers: this._executiveService.getHeaders()
    });
  }

  addDeliveryFeeRange(data: any): Observable<any>{
    return this.http.post(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getDeliveryFee}`, data, { 
      headers: this._executiveService.getHeaders()
     });
    
  }
  getDeliveryFeeRangeById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getDeliveryFee}/${id}`,
      
      {headers: this._executiveService.getHeaders()
  });
  }
  updateDeliveryFeeRange(id: any, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}${this.endpointsForDeliveryExecutive.getDeliveryFee}/${id}`, data, { 
      headers: this._executiveService.getHeaders()
     });
  }
  getRiderList(id?: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.riderList}?page=1&limit=500`, {
      headers: this._executiveService.getHeaders()
    });
  }
  getRiderListById(id?: string): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}${this.endpointsForDeliveryExecutive.riderList}${id}`,{
      headers:this._executiveService.getHeaders()
    });
  }
  getOngoingTaskAssigned(id:any):Observable<any>{
    const pernterId = this.getDeliveryPartnerId();
    return this.http.get<any>(`${this.oumsApiUrl}${this.endpointsForDeliveryExecutive.ongoingTask}${pernterId}?assigned=true`,{
      headers: this._executiveService.getHeaders()
    });
  }
  getOngoingTaskUnassigned(id:any):Observable<any>{
    const pernterId = this.getDeliveryPartnerId();
    return this.http.get<any>(`${this.oumsApiUrl}${this.endpointsForDeliveryExecutive.ongoingTask}${pernterId}?assigned=false`,{
      headers: this._executiveService.getHeaders()
    });
  }
  getCompleteOrder(timeRange?: string, page?: number, limit?: number,fromDate?:any,toDate?:any):Observable<any>{
    const pernterId = this.getDeliveryPartnerId();
    return this.http.get<any>(`${this.oumsApiUrl}${this.endpointsForDeliveryExecutive.completeOrder}${pernterId}?timeRange=${timeRange}&page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}`,{
      headers: this._executiveService.getHeaders()
    });
  }
  getAssignedRider():Observable<any>{
    const pernterId = this.getDeliveryPartnerId();
    return this.http.get<any>(`${this.oumsApiUrl}${this.endpointsForDeliveryExecutive.assignedRider}${pernterId}`,{
      headers: this._executiveService.getHeaders()
    });

  }
  getAssignOrder(data:any):Observable<any>{
    // const pernterId = this.getDeliveryPartnerId();
    return this.http.post<any>(`${this.oumsApiUrl}${this.endpointsForDeliveryExecutive.assignOrder}`,data,{
      headers: this._executiveService.getHeaders()
    });
  }

  getRiderStatus(payload: any): Observable<any> {
    const partnerId = this.getDeliveryPartnerId();
    if (!partnerId) {
      throw new Error('Partner ID not found');
    }
    const url = `${this.apiUrl}${this.endpointsForDeliveryExecutive.riderStatus}`;
    return this.http.put<any>(url, payload, {
      headers: this._executiveService.getHeaders()
    });
  }
}