<app-layout>
    <div class="main-container ongoing-container">
        <ng-container>
            <div class="form-main-container">
                <div class="form-inner-container">
                    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChange($event)">
                        <mat-tab label="Current" class="w-50">
                            <div class="tab-content">
                                <!-- <h3 class="tab-heading">Current</h3> -->
                                <app-ongoing-list  class="p-2"></app-ongoing-list>
                            </div>
                        </mat-tab>
                        <mat-tab label="Completed" class="w-50">
                            <div class="tab-content">
                                <!-- <h3 class="tab-heading">Completed</h3> -->
                                <app-completed-list  class="p-2"></app-completed-list>
                            </div>
                        </mat-tab>
                        <mat-tab label="History" class="w-50">
                            <div class="tab-content">
                                <!-- <h3 class="tab-heading">History</h3> -->
                                <app-history-list class="p-2"></app-history-list>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </ng-container>
    </div>
</app-layout>
