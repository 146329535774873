<div class="main-container">
  <div class="form-header-container">
    <h3 class="heading">Bank Information</h3>
  </div>
  <div class="form-main-container">
    <div class="form-inner-container">
      <form class="form-main" [formGroup]="bankInformation">
        <div class="form-main-inner row">

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
            <mat-form-field appearance="outline" class="w-100 pb-1">
              <mat-label>Bank Name</mat-label>
              <input type="text" formControlName="bankName" matInput placeholder="Enter Bank Name" />
              <mat-error *ngIf="bankInformation.get('bankName')?.invalid && bankInformation.get('bankName')?.touched
                        ">Bank Name is required</mat-error>
            </mat-form-field>

          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Account Holder Name</mat-label>
              <input type="text" formControlName="accountHolderName" matInput placeholder="Enter Account Holder Name" />
              <mat-error *ngIf="bankInformation.get('accountHolderName')?.invalid && bankInformation.get('accountHolderName')?.touched
                ">Account Holder Name is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Account Number </mat-label>
              <input type="text" formControlName="accountNumber" matInput placeholder="Enter Account Number " />
              <mat-error *ngIf="bankInformation.get('accountNumber')?.hasError('required') && bankInformation.get('accountNumber')?.touched
              ">Account Number is required</mat-error>
              <mat-error *ngIf="
              bankInformation.get('accountNumber')?.touched ||
              bankInformation.get('accountNumber')?.hasError('minlength')
            ">Account Number must be atleast 9 digit and max 16 digit</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-4 col-sm-4 col-xs-12 form-field pt-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>IFSC Code</mat-label>
              <input type="text" formControlName="ifscCode" matInput placeholder="Enter IFSC Code" />
              <mat-error *ngIf="     
                bankInformation.get('ifscCode')?.hasError('pattern')
              ">IFSC Code is Invalid (eg. HDFC0000181)</mat-error>
              <mat-error *ngIf="bankInformation.get('ifscCode')?.hasError('required') &&bankInformation.get('ifscCode')?.hasError('required') 
                ">IFSC Code is required</mat-error>

            </mat-form-field>
          </div>

        </div>

        <div>
          <div class="form-header-container pt-2">
            <h3 class="heading">Insurance Details</h3>
          </div>

          <div class="form-main-inner row">

            <div class="col-lg-4 col-sm-4 col-xs-12 form-field mb-2">
              <span>Upload Employee Insurance</span>
              <div class="pt-2 ">
                <div class="imageInput" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="1rem">
                  <img [src]="employeeInsuranceImg" />
                  <div class="edit-img-icon">
                    <input id="imgInput" formControlName="employeeInsurance" type="file"
                      (change)="onFileChangeEmployeeInsurance($event, 'employeeInsuranceImg')" accept="image/*" />
                    <label for="imgInput">Choose File</label>
                  </div>
                </div>
              </div>
              <mat-error
                *ngIf="bankInformation.get('employeeInsurance')?.touched && bankInformation.get('employeeInsurance')?.hasError('required')">Employee
                Insurance is required</mat-error>
            </div>
          </div>
        </div>

        <div class="form-main-action" fxLayoutGap="1rem">
          <button mat-raised-button class="primary-button-color" [disabled]="!validInfo() || !isImageUploaded"
            matStepperNext (click)="updateBankInfo()">
            Save & Continue
          </button>
          <button mat-raised-button class="primary-button-color" matStepperPrevious>
            Back
          </button>
        </div>
      </form>
    </div>
  </div>
</div>