import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { Pagination } from 'src/app/@core/models/pagination.model';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { TrackingPopupComponent } from '../ongoing-list/tracking-popup/tracking-popup.component';
import { AllOrdersComponent } from '../ongoing-list/all-orders/all-orders.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-completed-list',
  templateUrl: './completed-list.component.html',
  styleUrls: ['./completed-list.component.scss']
})
export class CompletedListComponent implements OnInit{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  pagination: Pagination = cloneDeep(PAGINATION);
  currentPageIndex: number = 0;
  pageSize: number = 10;
  selectedTimeRange: string = 'last7days';
  fromDate: Date | null = null; 
  toDate: Date | null = null;  
  dateRangeError: boolean = false;


  calculateSerialNumber(indexOnPage: number): number {
      return this.currentPageIndex * this.pageSize + indexOnPage + 1;
  }

  pageChanged(event: any): void {
    this.currentPageIndex = event.pageIndex;
    this.getCompleteOrder(); 
  }
  displayedColumns: string[] = [
    'srno',
    'orderId',
    'customerName',
    'customerAddress',
    'deliveryDateTime',
    'arrivalTime',
    'rider',
    'invoice'
   
  ];
  constructor(
    private _cdr: ChangeDetectorRef,
    private _executiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade:AppStoreFacade,
    private _dialog:MatDialog,
  ){}
  ngOnInit(): void {
    this.getCompleteOrder();
  }
  onTimeRangeChange(): void {
    this.dateRangeError = false; 
    if (this.selectedTimeRange !== 'custom') {
      this.getCompleteOrder(); 
    }
  }
  getCompleteOrder(){
    this._executiveService.getCompleteOrder(this.selectedTimeRange, this.currentPageIndex + 1, this.pageSize).pipe(
      tap((response:any)=>{
        this._appStoreFacade.updateLoading(false)
        if(response.success){
          this.dataSource.data = response?.data
          this.dataSource.paginator = this.paginator;

        } else{
          this._snackbarService.showError(response?.message)
        }
        this._cdr.markForCheck();
      })
    ).subscribe(
      ()=>{},
      (error:any)=>{
        this._snackbarService.showErrorAlert(error?.error?.message);
        this._cdr.markForCheck();
      }
    );
  }
  applyFilter(value: string) {
    value = value?.trim();
    value = value?.toLowerCase();
    this.dataSource.filter = value;
    this._cdr.markForCheck();
}
openTrackingDelivery(trackingOrder:any): void {
  this._dialog.open(TrackingPopupComponent, {
    data: trackingOrder,
    width: '50%',
    panelClass: 'order-details-dialog',
  });
}
openAllOrder(order: any):void{
  this._dialog.open(AllOrdersComponent, {
    data: order,
    width: '40%',
    panelClass: 'order-details-dialog',
  });
}
formatDateTime(dateTimeString: any) {
  if (!dateTimeString) {
    return '';
  }

  const date = new Date(dateTimeString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
}
