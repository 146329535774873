<div mat-dialog-content>
    <div class="tracking-popup">
        <div class=" ">
            <h3 class="pb-1">Order </h3>
            <span>{{ data.orderID }}</span>
        </div>
      
        <div class="tracking-timeline p-2">
            <div *ngFor="let history of data.orderItems; let i = index" class="timeline-item">
                <div class="timeline-content">
                    <div class="d-flex flex-row align-items-center m-1">
                        <img *ngIf="history.coverImage" [src]="history?.coverImage" class="proImg" alt="" width="100rem"/>
                       <div class="border-b p-2 d-flex justify-space-between">
                        {{ history.productName.split(' ').slice(0, 4).join(' ') + (history.productName.split(' ').length > 4 ? '...' : '') }}
                        <span *ngIf="history.productName"><strong>QTY:</strong>{{ history.quantity }}</span>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
