import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../delivery-executive.service';
import { DatePipe, formatDate } from '@angular/common';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { CommonService } from 'src/app/services/common/common.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';

@Component({
  selector: 'app-add-executive',
  templateUrl: './add-executive.component.html',
  styleUrls: ['./add-executive.component.scss'],
  providers: [{ provide: DatePipe }],
})
export class AddExecutiveComponent implements OnInit {
  createDeliveryExecutive!: FormGroup;
  allCities: any = [];
  allStates: any = [];
  maxDate = new Date();
  isPasswordHidden = true;
  isRePasswordHidden = true;
  constructor(
    private _cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService:AuthService,
    private _deliveryExecutiveService: DeliveryExecutiveService,
    private _snackbarService: SnackbarService,
    private _appStoreFacade:AppStoreFacade
  ) {
    this.createDeliveryExecutive = new FormGroup({
      fullName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$'),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      gender: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl(null, Validators.required),
      city: new FormControl('', [Validators.required]),
      permanentAddress: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      temporaryAddress: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      reEnterPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    },
    { validators: this._authService.passwordMatchValidator()});
    
  }
  ngOnInit() {
    this._commonService.getStates().subscribe((states: any) => {
      this.allStates = states?.data;
      this._snackbarService.showSuccess(states?.message);
      this._cdr.markForCheck();
    });
  }
  formatDateValue(dateValue: any): string {
    const date = new Date(dateValue);
    if (isNaN(date.getTime())) {
      return '';
    }
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }
  copyPermanentAddress() {
    const permanentAddress =
      this.createDeliveryExecutive.get('permanentAddress')?.value;
    this.createDeliveryExecutive
      .get('temporaryAddress')
      ?.setValue(permanentAddress);
  }
  createExecutive() {
    const fullName = this.createDeliveryExecutive.get('fullName')?.value;
    const email = this.createDeliveryExecutive.get('email')?.value;
    const phone_number =
      this.createDeliveryExecutive.get('phone_number')?.value;
    const DOB = this.createDeliveryExecutive.get('dateOfBirth')?.value;
    const dateOfBirth = this.formatDateValue(DOB);
    const gender = this.createDeliveryExecutive.get('gender')?.value;
    const state = this.createDeliveryExecutive.get('state')?.value;
    const city = this.createDeliveryExecutive.get('city')?.value;
    const permanentAddress =
      this.createDeliveryExecutive.get('permanentAddress')?.value;
    const temporaryAddress =
      this.createDeliveryExecutive.get('temporaryAddress')?.value;
    const password = this.createDeliveryExecutive.get('password')?.value;
    const reEnterPassword =
      this.createDeliveryExecutive.get('reEnterPassword')?.value;
      this._appStoreFacade.updateLoading(true);
    let addExecutiveObject = {
      fullName: fullName,
      email: email,
      dateOfBirth: dateOfBirth,
      phone_number: phone_number,
      gender: gender,
      state: state,
      city: city,
      permanentAddress: permanentAddress,
      temporaryAddress: temporaryAddress,
      password: password,
      reEnterPassword: reEnterPassword,
    };

    if (this.createDeliveryExecutive?.valid) {
      this._deliveryExecutiveService
        .addDeliveryExecutive(addExecutiveObject)
        .subscribe(
          (response: any) => {
            this._appStoreFacade.updateLoading(false);
            if (response?.success) {
              // this._appStoreFacade.updateUser(response?.data);
              this._snackbarService.showSuccess(response?.message);
              this._router.navigate(['/delivery-executive/executive-list']);
              this._cdr.markForCheck();
            }
          },
          (error: any) => {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showError(error?.error?.message);
          }
        );
    }
  }
  validInfo() {
    return this.createDeliveryExecutive.valid;
  }

  fetchCitiesByState(state_id: string) {
    this._appStoreFacade.updateLoading(true);
    if (!!state_id) {
      this._commonService.getCities(state_id).subscribe(
        (response: any) => {
          this._appStoreFacade.updateLoading(false);
          this.allCities = response?.cities;
          this._snackbarService.showSuccess(response?.message);
          this._cdr.markForCheck();
        },
        (error: any) => {
          this._appStoreFacade.updateLoading(false);
          this._snackbarService.showError(error?.error?.message);
          this._cdr.markForCheck();
        }
      );
    }
  }

  navigateTo() {
    this._router.navigate(['/delivery-executive/executive-list']);
  }
}
