import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MyAccountServiceService } from '../../my-account/my-account-service.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-executive',
  templateUrl: './update-executive.component.html',
  styleUrls: ['./update-executive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateExecutiveComponent implements OnInit {
  updateDeliveryExecutive!: FormGroup;
  vendorCurrentStep: number | undefined;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
  ) {

    this.updateDeliveryExecutive = new FormGroup({
      name: new FormControl('', Validators.required),
      phone_number: new FormControl('', [Validators.required,Validators.pattern('^[0-9]{10}$')]),
      email: new FormControl('', [Validators.required,Validators.email]),
      gender: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      permanentAddress: new FormControl('', Validators.required),
      temporaryAddress: new FormControl('', Validators.required),
      aadhaar:new FormControl('', [Validators.required]),
      pan:new FormControl('', [Validators.required]),
      license:new FormControl('', [Validators.required]),
    });

  }
  ngOnInit() {
    
  }
  updateExecutiveCurrentStep(stepIndex: number) {
    this.vendorCurrentStep = stepIndex;
    localStorage.setItem('vendorCurrentStep', stepIndex.toString());
    this._cdr.markForCheck();
  }
  
}
