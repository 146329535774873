import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { CommonService } from 'src/app/services/common/common.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { DeliveryExecutiveService } from '../../delivery-executive.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-view-commission-slab',
  templateUrl: './view-commission-slab.component.html',
  styleUrls: ['./view-commission-slab.component.scss']
})
export class ViewCommissionSlabComponent {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['minKm', 'maxKm','charges', 'action'];
  pagination = cloneDeep(PAGINATION);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isLoading = true;
  error: string | null = null;
  delveryViewId: any;
  deliveryType:any;
  parentDeliveryFeeId:any
  private _swalService: any;

  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _snackbarService: SnackbarService,
    private _commonService: CommonService,
    private _appStoreFacade:AppStoreFacade,
    private _executiveService: DeliveryExecutiveService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.delveryViewId = params['id'];
      this.getDeliveryFee(this.delveryViewId);
    });
  }

  getDeliveryFee(delveryViewId: any) {
    this._executiveService.getDeliveryFee(delveryViewId).pipe(
      tap((response: any) => {
        this.isLoading = false;
        if (response?.success) {
          this.dataSource.data = response?.data;
          this._snackbarService.showSuccess(response?.message);
        } else {
         (error: any) => {
            this._snackbarService.showError(error?.error?.message);
          }
        }
      }),
    ).subscribe(
      () => { },
      (error:any) => {
        console.error('API Error:', error);
        this._cdr.markForCheck();
      }
    );
  }

  redirectActions(action: string, id: any) {
    if (action === 'create') { 
      localStorage.setItem('parentDeliveryFeeId', id)
      this._router.navigate(['/commission-slab/fee-renge/create',]);
    } else if (action === 'edit') {
      this._router.navigate(['commission-slab/fee-renge/edit', id]);
    }
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}