<app-layout>
  <div class="main-container">
    <div class="table-header-container d-flex justify-space-between align-items-center">
      <h2 class="heading">Commission Slab List</h2>
      <button mat-raised-button class="primary-button-color lh-1" (click)="redirectActions('create')">Create Slab</button>
      <!-- <button mat-raised-button color="primary" class="p-2 lh-1" (click)="redirectActions('create')">Create Slab</button> -->
    </div>
    <div class="table-search-container">
      <div class="table-search-inner">
        <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search"
          class="search-input">
        <mat-icon class="search-icon">search</mat-icon>
      </div>
    </div>
    <div class="table-main-container">
      <div class="table-inner-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="srno">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{calculateSerialNumber(i)}} </td>
          </ng-container>

          <ng-container matColumnDef="commissionSlabName">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Slab Name </th>
            <td mat-cell *matCellDef="let element"> {{element?.slabName}} </td>
          </ng-container>

          <ng-container matColumnDef="aplicable">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Aplicable </th>
            <td mat-cell *matCellDef="let element"> {{element?.applicable}} </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> State </th>
            <td mat-cell *matCellDef="let element"> {{element?.state_name}} </td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> City </th>
            <td mat-cell *matCellDef="let element"> {{element?.city_name}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Action </th>
            <td mat-cell *matCellDef="let element; let index = index" style="position: relative;">
              <div class="action-icon-box">
                <button class="mat-icon-button" matTooltip="View"> <mat-icon class="custom-icon-eye"
                    (click)="redirectActions('view', element._id)">remove_red_eye</mat-icon></button>
                <button class="mat-icon-button" matTooltip="Edit"><mat-icon class="custom-icon-edit"
                    (click)="redirectActions('edit', element._id)">edit</mat-icon></button>
                <button class="mat-icon-button" matTooltip="Delete"><mat-icon class="custom-icon-delete"
                    (click)="deleteCommissionSlab(element)">delete_outline</mat-icon></button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator class="custom-paginator" (page)="onPageChange($event)"
          [length]="pagination.totalItems" [pageSize]="pagination.perPage" [showFirstLastButtons]="false"
          [pageSizeOptions]="[10, 20, 30]" [hidePageSize]="false" [pageIndex]="pagination.currentPage - 1"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </div>
</app-layout>