<mat-sidenav-container class="container" autosize>
    <mat-sidenav #sidenav class="sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()">
        <mat-nav-list>
            <mat-list-item>
                <div style="padding:0 0.5rem;display: flex;" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon mat-list-icon  style=" color: #2974F0; padding-right: 0.5rem;">dashboard</mat-icon>
                    <a style="text-decoration: none;color: black;" class="full-width" routerLinkActive="active" routerLink="/dashboard"
                        *ngIf="isExpanded || isShowing">&nbsp;Dashboard </a>
                </div>

            </mat-list-item>

            <mat-list-item style="padding:0 0.5rem;" (click)="showSubmenu = !showSubmenu" class="parent">
                <mat-icon mat-list-icon  style=" color: #2974F0;">handshake</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Partners</span>

                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
                <mat-list-item>
                    <a mat-list-item routerLinkActive="active" routerLink="/partner">
                        <img mat-list-img src="/../../../assets/delivery-icon.svg" width="22px" height="22px"> &nbsp; Delivery Partner </a>
                </mat-list-item>
                <!-- <a mat-list-item routerLinkActive="active" routerLink="...">In Review</a> -->
                <mat-list-item>
                    <a mat-list-item routerLinkActive="active" routerLink="/vendor">
                        <img mat-list-img src="/../../../assets/vendor-icon.svg" width="22px" height="22px"> &nbsp; Vendor</a>
                </mat-list-item>
            </div>
            <mat-list-item style="padding:0 0.5rem;" (click)="showSubmenu2 = !showSubmenu2" class="parent">
                <mat-icon mat-list-icon style=" color: #2974F0;">settings</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Master Settings</span>

                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu2}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu2}" *ngIf="isShowing || isExpanded">
                <mat-list-item>
                          <a routerLinkActive="active" routerLink="/product-variant">
                          <img mat-list-img src="/../../../assets/action_key.svg" width="22px" height="22px"> &nbsp;Product Variants</a>
                </mat-list-item>
                <mat-list-item>
                        <a mat-list-item routerLinkActive="active" routerLink="/brands">
                        <img mat-list-img src="/../../../assets/brand-icon.svg" width="22px" height="22px"> &nbsp;Brands</a>
                </mat-list-item>
                <mat-list-item>
                        <a mat-list-item routerLinkActive="active" routerLink="/category">
                        <img mat-list-img src="/../../../assets/category-icon.svg" width="22px" height="22px"> &nbsp;Category</a>
                </mat-list-item>
            </div>
        </mat-nav-list>
        <mat-nav-list>
        </mat-nav-list>
    </mat-sidenav>

    <div style="padding: 2rem;">
        <ng-content ></ng-content>
    </div>
    <!-- <app-dashboard ></app-dashboard> -->
    <!-- <ng-content select=".dashboard"></ng-content> -->
    <!-- <div class="sidenav-content">
        <router-outlet style="width: 100%;"></router-outlet>
    </div> -->

</mat-sidenav-container>