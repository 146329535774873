<app-layout>
  <mat-card class="card">
    <div class="main-container">
      <div class="form-header-container">
        <h3 class="heading">Add Executive</h3>
      </div>
      <div class="form-main-container">
        <div class="form-inner-container">
          <form class="form-main" [formGroup]="createDeliveryExecutive">
            <div class="form-main-inner row">
              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Full Name</mat-label>
                  <input type="text" formControlName="fullName" matInput placeholder="Enter Name" />
                  <mat-error *ngIf="createDeliveryExecutive.get('fullName')?.hasError('required')">
                    Name is required
                  </mat-error>
                  <mat-error *ngIf="createDeliveryExecutive.get('fullName')?.hasError('maxlength')">
                    Name cannot be more than 50 characters
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email</mat-label>
                  <input type="text" formControlName="email" matInput placeholder="Enter Email" />
                  <mat-error *ngIf="createDeliveryExecutive.get('email')?.hasError('email')">
                    Enter valid Email
                  </mat-error>
                  <mat-error
                    *ngIf="createDeliveryExecutive.get('email')?.touched && createDeliveryExecutive.get('email')?.hasError('required')">
                    Email is required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Phone number</mat-label>
                  <input type="text" formControlName="phone_number" matInput placeholder="Enter phone number" />
                  <mat-error *ngIf="createDeliveryExecutive.get('phone_number')?.hasError('pattern')">Phone Number must
                    be 10
                    digits</mat-error>
                  <mat-error
                    *ngIf="createDeliveryExecutive.get('phone_number')?.touched && createDeliveryExecutive.get('phone_number')?.hasError('required')">Phone
                    Number is required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Date of Birth</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" readonly [max]="maxDate">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="createDeliveryExecutive.get('dateOfBirth')?.hasError('required')">
                    Date Of Birth is required
                  </mat-error>
                </mat-form-field>
              </div>


              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Gender</mat-label>
                  <mat-select formControlName="gender" required>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                  <mat-error *ngIf="createDeliveryExecutive.get('gender')?.hasError('required')">Gender is
                    Required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state" (selectionChange)="fetchCitiesByState($event.value)" required>
                    <mat-option value="">Select State</mat-option>
                    <mat-option *ngFor="let state of allStates" [value]="state?._id">{{ state?.name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                  createDeliveryExecutive.get('state')?.touched &&
                  createDeliveryExecutive.get('state')?.hasError('required')
                            ">State is required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>City</mat-label>
                  <mat-select formControlName="city" required>
                    <mat-option value="">Select City</mat-option>
                    <mat-option *ngFor="let city of allCities" [value]="city?.id">{{ city?.city }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                  createDeliveryExecutive.get('city')?.touched &&
                  createDeliveryExecutive.get('city')?.hasError('required')
                            ">City is required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Permanent Address</mat-label>

                  <textarea type="text" formControlName="permanentAddress" matInput
                    placeholder="Enter Permanent Address"></textarea>
                  <mat-error *ngIf="
                                createDeliveryExecutive.get('permanentAddress')?.touched &&
                                createDeliveryExecutive.get('permanentAddress')?.hasError('required')
                              ">Permanent Address is required</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Temporary Address</mat-label>
                  <textarea type="text" formControlName="temporaryAddress" matInput
                    placeholder="Enter Temporary Address"></textarea>
                  <mat-error *ngIf="
                                createDeliveryExecutive.get('temporaryAddress')?.touched &&
                                createDeliveryExecutive.get('temporaryAddress')?.hasError('required')
                              ">Temporary Address is required</mat-error>
                </mat-form-field>
                <mat-checkbox mat-raised-button color="primary" (click)="copyPermanentAddress()">Same as Permanent
                  Address</mat-checkbox>
              </div>

            </div>

            <div>
              <div class="form-header-container">
                <h3 class="heading">Security Information</h3>
              </div>

              <div class="form-main-inner row">
                <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Password</mat-label>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <input matInput formControlName="password" autocomplete="new-password"
                        [type]="isPasswordHidden ? 'password' : 'text'" placeholder="Enter your password" required>
                      <mat-icon (click)="isPasswordHidden = !isPasswordHidden">{{isPasswordHidden ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                    </div>

                    <mat-error *ngIf="
                    createDeliveryExecutive.get('password')?.touched &&
                    createDeliveryExecutive.get('password')?.hasError('required')
                  ">Password is required</mat-error>
                  <mat-error *ngIf="
                  createDeliveryExecutive.get('password')?.hasError('minlength')
                ">Enter valid password</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Confirm Password</mat-label>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <input matInput formControlName="reEnterPassword" autocomplete="new-password"
                        [type]="isRePasswordHidden ? 'password' : 'text'" placeholder="Enter your password" required>
                      <mat-icon (click)="isRePasswordHidden = !isRePasswordHidden">{{isRePasswordHidden ?
                        'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                    <mat-error *ngIf="
                    createDeliveryExecutive.get('reEnterPassword')?.touched &&
                    createDeliveryExecutive.get('reEnterPassword')?.hasError('required')
                  ">Confirm Password is required</mat-error>
                  <mat-error *ngIf="createDeliveryExecutive.hasError('passwordMismatch')">
                    Passwords do not match
                  </mat-error>
                  </mat-form-field>
                </div>
              </div>


            </div>

            <div class="form-main-action" fxLayoutGap="1rem">
              <button mat-raised-button class="primary-button-color" [disabled]="!validInfo()" (click)="createExecutive()">
                Create Executive
              </button>
              <button mat-raised-button class="primary-button-color" (click)="navigateTo()">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card>
</app-layout>