import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  allStates: any = [];
  allCities:any = [];
  private apiUrl = environment.API_URL;
  currentPageIndex: number = 0;
  endpoints = {
    getCities: '/state-city/cities',
    getStates: '/state-city/states',
    getAllCity:"/state-city/allCities"
  }

  constructor(
    private _http: HttpClient,
    private _authService: AuthService,
    private _snackbarService: SnackbarService
  ) {
    this.getStates().subscribe(
      (response: any) => {
        this.allStates = response?.data;
        this._snackbarService.showSuccess(response?.message);
      },
      (error: any) => {
        this._snackbarService.showError(error?.error?.message);
    }); 
   }

  getStates(): Observable<any[]> {
    return this._http.get<any>(`${this.apiUrl}${this.endpoints.getStates}`, { 
      headers: this._authService.getHeaders()
     });
  }
  getCities(state_id: any): Observable<any[]> {
    return this._http.get<any>(`${this.apiUrl}${this.endpoints.getCities}/${state_id}`, { 
      headers: this._authService.getHeaders()
     });
  }

   getStateName(stateId: string): string {
    const state = this.allStates?.find((state: any) => state._id === stateId);
    return state ? state.name : '';
  }
  getCityName(): Observable<any[]> {
    return this._http.get<any>(`${this.apiUrl}${this.endpoints.getAllCity}`, { 
      headers: this._authService.getHeaders()
     });
  }
  resetPagination() {
    this.currentPageIndex = 0;
  }
}
