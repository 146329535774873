<app-layout>
    <div class="main-container">
      <div class="table-header-container d-flex justify-space-between align-items-center">
        <h2 class="heading"> Rider List</h2>
        <div class="date-refresh-container d-flex align-items-center justify-space-between">
            <span class="border-1 p-1 rounded-4 mx-1"><small><b>{{ currentDate | date: 'EEE dd MMM yyyy' }}</b></small></span>
            <button mat-icon-button (click)="refreshRiderList()" class="p-1 primary-button-color rounded-4">
              <mat-icon mat-list-icon  class="primary-button-color">refresh</mat-icon>
            </button>
          </div>
      </div>
      <div class="table-search-container">
        <div class="table-search-inner">
          <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search"
            class="search-input">
        </div>
      </div>
      <div class="table-main-container">
        <div class="table-inner-contain\er">
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="srno">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
              <td mat-cell *matCellDef="let element; let i = index"> {{ calculateSerialNumber(i) }} </td>
            </ng-container>
  
            <ng-container matColumnDef="riderName">
              <th mat-header-cell *matHeaderCellDef class="small-heading">Rider Name </th>
              <td mat-cell *matCellDef="let element"> {{element?.personalInformation.fullName}} </td>
            </ng-container>
  
            <ng-container matColumnDef="mobileNo">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Mobile No. </th>
              <td mat-cell *matCellDef="let element"> {{element?.personalInformation?.phone_number}} </td>
            </ng-container>
  
            <ng-container matColumnDef="vehicleDetail">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Vehicle Detail </th>
              <td mat-cell *matCellDef="let element"> {{element?.vehicleInformation?.vehicleRegistrationNumber || '-----'}} </td>
            </ng-container>
  
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="small-heading"> Status </th>
              <td mat-cell *matCellDef="let element">
                <mat-slide-toggle
                  [ngModel]="element.status === 'Online'"
                  (ngModelChange)="onStatusToggle($event, element)"
                  [ngModelOptions]="{ standalone: true }"
                >
                </mat-slide-toggle>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="loginDuration">
                <th mat-header-cell *matHeaderCellDef class="small-heading"> Login Duration </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.loggedInTime ? (element?.loggedInTime.hours + ' hrs ' + element?.loggedInTime.minutes + ' mins') : '-----' }}
                </td>
              </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons (page)="pageChanged($event)"
            aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
      </div>
    </div>
  </app-layout>