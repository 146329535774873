<app-layout>
    <div class="main-container">
        <div class="form-header-container">
            <h3 class="heading">{{ deliveryexecutiveFeeSlabId ? 'Update' : 'Create' }}   Fee Range</h3>
        </div>
        <div class="form-main-container">
            <div class="form-inner-container">
                <form class="form-main" [formGroup]="createDeliveryFeeRangeForm">
                    <div class="form-main-inner row">
                            <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Min Km</mat-label>
                                    <input formControlName="minKm" type="number" matInput placeholder="Enter Min Km" />
                                    <mat-error *ngIf="createDeliveryFeeRangeForm?.get('minKm')?.hasError('required')">Min Km is required</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Max Km</mat-label>
                                    <input formControlName="maxKm" type="number" matInput placeholder="Enter Max Km" />
                                    <mat-error *ngIf="createDeliveryFeeRangeForm?.get('maxKm')?.hasError('required')">Max Km is required</mat-error>
                                </mat-form-field>
                            </div>

                        <div class="col-lg-4 col-sm-4 col-xs-12 form-field pb-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Charges</mat-label>
                                <input formControlName="charges" type="number" matInput placeholder="Enter Charges" />
                                <mat-error *ngIf="createDeliveryFeeRangeForm?.get('charges')?.hasError('required')">Charges are required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>   
                    <div class="form-main-action">
                        <button mat-raised-button color="primary" (click)="onDeliveryFeeRangeSubmit()">
                            {{ deliveryexecutiveFeeSlabId ? 'Update' : 'Create' }} Fee Renge
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-layout>