import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { SnackbarService } from '../common/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class BellNotificationService {
  private newOrderSubject = new BehaviorSubject<any>(null);
  newOrder$: Observable<any> = this.newOrderSubject.asObservable();
  public audio = new Audio('assets/Notification/livechat-129007.mp3');
  public soundEnabled = true;
  private pollInterval: any;
  private previousNotificationsCount = 0;
  public notifications$ = new Subject<any[]>();


  endpoints = {
    getNotification: '/vendor/bell/notification',
    deleteNotification:'/notification/delete',
    clearAllOrder:'/notification/clear'
  }
  private apiUrl = environment.API_URL;
  constructor(private _http: HttpClient, private _authService: AuthService,private _snackBarService: SnackbarService, ) {this.startPolling(); }
  startPolling() {
    this.pollInterval = setInterval(() => {
      this.checkForNewNotifications();
    }, 6000);
  }
  getNotification() {
    return this._http.get<any>(`${this.apiUrl}${this.endpoints.getNotification}`, {
      headers: this._authService.getHeaders()
    });
  }
  notifyNewOrder(order: any) {
    this.newOrderSubject.next(order);
  }

  showSnackBarNotification(message: string, orderID: string) {
    this._snackBarService.showSnackbar(`${message} Order ID: ${orderID}`, 'Close', 5000);
  }
  deleteNotification(index:any):Observable<any>{
    return this._http.put<any>(`${this.apiUrl}${this.endpoints.deleteNotification}`,{ Notification },{
      headers: this._authService.getHeaders()
    })
  }
  clearAllOrder():Observable<any>{
    return this._http.put<any>(`${this.apiUrl}${this.endpoints.clearAllOrder}`,{},{
      headers: this._authService.getHeaders()
    })
  }
  checkForNewNotifications() {
    this.getNotification().subscribe(
      (response) => {
        console.log('Notifications Response:', response);
        const newNotificationCount = response?.data?.length ?? 0;
        if (newNotificationCount > this.previousNotificationsCount) {
          this.playSound();
        }
        this.previousNotificationsCount = newNotificationCount;
        this.notifications$.next(response.data || []);
      },
      (error) => {
        console.error('Error fetching notifications', error);
      }
    );
  }
  enableSound() {
    this.soundEnabled = true;
    this.playSound();
  }
  playSound() {
    if (this.soundEnabled) {
      this.audio.play().catch(error => {
        console.error('Error playing notification sound', error);
      });
    }
  }
}
