import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeliveryExecutiveRoutingModule } from './delivery-executive-routing.module';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { AddExecutiveComponent } from './add-executive/add-executive.component';
import { UpdateExecutiveComponent } from './update-executive/update-executive.component';
import { LayoutModule } from 'src/app/shared/components/layout/layout.module';
import { MaterialModule } from 'src/app/shared/module/material-module/material-module.module';
import { DeliveryExecutiveStepOneComponent } from './update-executive/delivery-executive-step-one/delivery-executive-step-one.component';
import { DeliveryExecutiveStepTwoComponent } from './update-executive/delivery-executive-step-two/delivery-executive-step-two.component';
import { DeliveryExecutiveStepThreeComponent } from './update-executive/delivery-executive-step-three/delivery-executive-step-three.component';
import { DeliveryExecutiveStepFourComponent } from './update-executive/delivery-executive-step-four/delivery-executive-step-four.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditCommissionSlabComponent } from './commission-slab/add-edit-commission-slab/add-edit-commission-slab.component';
import { AddEditFeeRengeComponent } from './commission-slab/add-edit-fee-renge/add-edit-fee-renge.component';
import { ViewCommissionSlabComponent } from './commission-slab/view-commission-slab/view-commission-slab.component';
import { CommissionSlabComponent } from './commission-slab/commission-slab.component';
import { RiderListComponent } from './rider-list/rider-list.component';
import { OngoingTaskComponent } from './ongoing-task/ongoing-task.component';
import { CompletedListComponent } from './ongoing-task/completed-list/completed-list.component';
import { HistoryListComponent } from './ongoing-task/history-list/history-list.component';
import { OngoingListComponent } from './ongoing-task/ongoing-list/ongoing-list.component';
import { AssignedOrdersPopupComponent } from './ongoing-task/ongoing-list/assigned-orders-popup/assigned-orders-popup.component';
import { TrackingPopupComponent } from './ongoing-task/ongoing-list/tracking-popup/tracking-popup.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AllOrdersComponent } from './ongoing-task/ongoing-list/all-orders/all-orders.component';


@NgModule({
  declarations: [
    ExecutiveListComponent,
    AddExecutiveComponent,
    UpdateExecutiveComponent,
    DeliveryExecutiveStepOneComponent,
    DeliveryExecutiveStepTwoComponent,
    DeliveryExecutiveStepThreeComponent,
    DeliveryExecutiveStepFourComponent,
    AddEditCommissionSlabComponent,
    AddEditFeeRengeComponent,
    ViewCommissionSlabComponent,
    CommissionSlabComponent,
    RiderListComponent,
    OngoingTaskComponent,
    CompletedListComponent,
    HistoryListComponent,
    OngoingListComponent,
    AssignedOrdersPopupComponent,
    TrackingPopupComponent,
    AllOrdersComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    DeliveryExecutiveRoutingModule,
  ],
})
export class DeliveryExecutiveModule {}
