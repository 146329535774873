<div class="table-main-container">
    <div class="table-inner-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="rider">
          <th mat-header-cell *matHeaderCellDef class="small-heading">Rider Name</th>
          <td mat-cell *matCellDef="let element">{{element?.personalInformation?.fullName}}</td>
        </ng-container>
  
        <ng-container matColumnDef="vehicleDetail">
            <th mat-header-cell *matHeaderCellDef class="small-heading"> Vehicle Detail </th>
            <td mat-cell *matCellDef="let element"> {{element?.vehicleInformation?.vehicleRegistrationNumber || '-----'}} </td>
          </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="small-heading">Action</th>
          <td mat-cell *matCellDef="let element">
            <button mat-button (click)="getAssignOrder(element)" class="shadow p-1 border-1">Assign</button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  