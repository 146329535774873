import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userId:any
  private apiUrl = environment.API_URL;
  private readonly TOKEN_KEY = 'access_token';
  private readonly USER_ROLE_KEY = 'userRole';
  private readonly VENDOR_ID_KEY = 'vendorId';
  partnerType:any;
  apiEndPoint = {
    login:"/user/login",
    changePassword:"/user/change-password",
    createPassword:"/user/confirm-password",
    statusChange:"/vendor/storeStatus",
  }
  constructor(private http: HttpClient) {}

  setAuthToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getAuthToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }
  createPasswordApi(payLoad: any, token: string) {
    return this.http.put<any>(`${this.apiUrl}${this.apiEndPoint.createPassword}?token=${token}`, payLoad);
  }
  clearAuthToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.VENDOR_ID_KEY);
  }
   setVendorId(vendorId: string): void {
    localStorage.setItem(this.VENDOR_ID_KEY, vendorId);
  }
  getVendorId(): string | null {
    return localStorage.getItem(this.VENDOR_ID_KEY);
  }
  getUserId(): string | null {
  const userData = localStorage.getItem('userData');
  if (userData) {
    const parsedData = JSON.parse(userData);
    return parsedData._id || null;
  }
  return null;
}

 login(credentials: any): Observable<any> {
  return this.http.post(`${this.apiUrl}/user/login`, credentials).pipe(
    map((result: any) => {
      if (result && result.userRole) {
        this.userRole = result.userRole;
      }
      if (result && result.token) {
        this.setAuthToken(result.token);
      }
      // Store essential details only
      if (result && result.data) {
        const userData = {
          _id: result.data._id,
          name: result.data.name,
          email: result.data.email
        };
        localStorage.setItem('userData', JSON.stringify(userData));
      }
      return result;
    })
  );
}


  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.getAuthToken()) {
      headers = headers.set('Authorization', `Bearer ${this.getAuthToken()}`);
    }
    return headers;
  }
  changePasswordApi(payLoad: any, token: string) {
    return this.http.post<any>(`${this.apiUrl}${this.apiEndPoint.changePassword}`, payLoad);
  }

  set userRole(role: string | any) {
    if (role) {
      localStorage.setItem(this.USER_ROLE_KEY, this.userRole);
    } else {
      localStorage.removeItem(this.USER_ROLE_KEY);
    }
  }

  passwordValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control.value;
      const errors: any = {};
      if (value.length < 8) {
        errors.minLength = true;
      }
      if (!/[A-Z]/.test(value)) {
        errors.uppercase = true;
      }
      if (!/[a-z]/.test(value)) {
        errors.lowercase = true;
      }
      if (!/\d/.test(value)) {
        errors.digit = true;
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        errors.specialCharacter = true;
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
    const oldPassword = control?.get('oldPassword')?.value;
    const newPassword = control?.get('newPassword')?.value;
    const reenterPassword = control?.get('reenterPassword')?.value;
    if (newPassword  && newPassword === oldPassword) {
      control.get('newPassword')?.setErrors({ sameAsOld: true });
      return { sameAsOld: false };
    }
    return newPassword === reenterPassword ? null : { passwordMismatch: true };
    };
  }
  changeStatus(id: any, status:any) {
    const payload = { store_status: status };
    return this.http.put<any>(`${this.apiUrl}${this.apiEndPoint.statusChange}/${id}`,payload);
  }
}
