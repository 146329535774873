import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProductCatelogueServiceService } from 'src/app/pages/product-catelogue/product-catelogue-service.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {
  @Input() isSidenavExpanded: boolean = false;
  @Input() totalOutOfStock: number = 0;
  userRole: string | any;
  constructor(
    private _route: Router,
    private _authService: AuthService,
    private _productCatalogueService: ProductCatelogueServiceService,
    private breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('partnerType');
    this._productCatalogueService.outOfStock$.subscribe((count) => {
      this.totalOutOfStock = count;
    });
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          // Adjust the sidenav state based on screen size
          if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
            this.isSidenavOpened = false;  // Hide sidenav for small devices
          } else {
            this.isSidenavOpened = true;  // Show sidenav for medium and larger screens
          }
        }
        this._cdr.markForCheck();
      });

  }

  @ViewChild('sidenav') sidenav: any;

  category = false;
  brand = false;
  isSidenavOpened = true;
  partner = false;
  forms = false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isExpanded2 = true;
  showSubmenu2: boolean = false;
  isShowing2 = false;
  showSubSubMenu2: boolean = false;
  showSubmenu3: boolean = false;
  showSubmenu4: boolean = false;

  logout(): void {
    localStorage.removeItem('token');
    this._authService.userRole = this.userRole;
    this._route.navigate(['/']);
  }

  categoryCheckIn(): void {
    this.category = true;
  }

  brandCheckIn(): void {
    this.brand = true;
  }

  partnerCheckIn(): void {
    this.partner = true;
  }

  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  onSidenavToggle(isExpanded: boolean): void {
    this.sidenav.toggle();
    this.isSidenavOpened = !this.isSidenavOpened;
  }

  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  mouseenter2(): void {
    if (!this.isExpanded2) {
      this.isShowing2 = true;
    }
  }

  mouseleave2(): void {
    if (!this.isExpanded2) {
      this.isShowing2 = false;
    }
  }
}
