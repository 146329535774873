import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { PAGINATION } from 'src/app/@core/constants/pagination.constants';
import { CommonService } from 'src/app/services/common/common.service';
import { SnackbarService } from 'src/app/services/common/snackbar.service';
import { SweetAlertService } from 'src/app/services/common/sweet-alert.service';
import { DeliveryExecutiveService } from '../delivery-executive.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-commission-slab',
  templateUrl: './commission-slab.component.html',
  styleUrls: ['./commission-slab.component.scss']
})
export class CommissionSlabComponent {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['srno', 'commissionSlabName', 'aplicable', 'state', 'city', 'action'];
  pagination = PAGINATION;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataPageCache: any = [];
  pageSize: number = 5;
  currentPageIndex: number = 0;
  
  currentPage = 1; 
  allCity:any=[]
  calculateSerialNumber(indexOnPage: number): number {
    return this.currentPageIndex * this.pageSize + indexOnPage + 1;
}

pageChanged(event: any) {
    this.currentPageIndex = event.pageIndex;
}

  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _snackbarService: SnackbarService,
    private _swalService: SweetAlertService,
    private _commonService: CommonService,
    private _appStoreFacade:AppStoreFacade,
    private _executiveService: DeliveryExecutiveService,
  ){

  }

  ngOnInit(): void {
    this.getCommissionSlab(this.pagination.currentPage, this.pagination.perPage);
  }

  getCommissionSlab(pageNumber: number, pageSize: number){
    this._executiveService.getCommissionSlab(pageNumber, pageSize).pipe(
      tap((response: any) => {
        if (response?.success) {
          this.dataPageCache.push(this.pagination.currentPage);
          this.dataSource.data = [...this.dataSource.data, ...response.data];
          this.pagination = response?.pagination;
          this.dataSource.paginator = this.paginator;
          this._snackbarService.showSuccess(response?.message);
          this._cdr.markForCheck();
        } else {
          this._snackbarService.showError(response?.message);
        }
        this._commonService.resetPagination();
        this.resetPaginator();
        this._cdr.markForCheck();
      })
    ).subscribe(
      () => {}, 
      (error) => {
        console.error('API Error:', error);
        this._cdr.markForCheck();
      }
    );
  }

  applyFilter(value: string) {
    value = value?.trim(); 
    value = value?.toLowerCase(); 
    this.dataSource.filter = value;
    this._cdr.markForCheck();
  }

  onPageChange(event: any) {
    this.currentPage = event?.pageIndex + 1; 
    this.pagination.currentPage = this.currentPage;
    this.pagination.perPage = event?.pageSize;
    if (!this.dataPageCache.includes(this.currentPage)) {
      this.getCommissionSlab(this.currentPage, this.pagination.perPage);  
      this._cdr.markForCheck();
    } else {
      this.dataSource.paginator = this.paginator;
      this._cdr.markForCheck();
    }
  }

  redirectActions(action: string, id?: string) {
    console.log(id)
    if(id){
      localStorage.setItem("SlabIdForDeliveryPartnerRange",id)

    }
    if(action === 'create'){
      this._router.navigate(['/commission-slab/create']);
    } else if(action === 'edit'){
      this._router.navigate(['/commission-slab/edit', id]);
    }else if (action === 'view') {
      this._router.navigate(['/commission-slab/view', id]);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
 resetPaginator() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }
  deleteCommissionSlab(row: any) {
    this._swalService.showDeleteAlert().then((result) => {
      this._appStoreFacade.updateLoading(true);
      if (result?.isConfirmed) {
        this._appStoreFacade.updateLoading(true);
        const id = row._id;
        this._executiveService.deleteCommissionSlab(id).subscribe(
          (response: any) => {
            this._appStoreFacade.updateLoading(false);
            const index = this.dataSource?.data?.findIndex(
              (item: any) => item._id === id
            );
            if (index > -1) {
              this.dataSource?.data?.splice(index, 1);
              this.dataSource._updateChangeSubscription();
            }
            this._swalService.showDeleteSuccess();
          },
          (error: any) => {
            this._appStoreFacade.updateLoading(false);
            this._snackbarService.showError(error?.error?.message);
          }
        );
      }
      this._appStoreFacade.updateLoading(false);
    });
  }
}