<div class="main-container">
    <div class="table-search-container">
        <div class="table-search-container w-100">
            <div class="table-search-inner">
                <input (keyup)="applyFilter($any($event.target).value)" matInput type="text" placeholder="Search"
                    class="search-input">
            </div>
        </div>
    </div>
    <div class="table-main-container">
        <div class="table-inner-container">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="srno">
                    <th mat-header-cell *matHeaderCellDef class="small-heading"> Sr.No </th>
                    <td mat-cell *matCellDef="let element; let i = index"> {{ calculateSerialNumber(i) }} </td>
                </ng-container>

                <ng-container matColumnDef="orderId">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Order Id</th>
                    <td mat-cell *matCellDef="let element" (click)="openAllOrder(element)"> {{element?.orderID}} </td>
                </ng-container>

                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Customer Name </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.name}} </td>
                </ng-container>

                <ng-container matColumnDef="customerAddress">
                    <th mat-header-cell *matHeaderCellDef class="small-heading"> Customer Address </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails.deliveryAddress?.address}} </td>
                </ng-container>

                <ng-container matColumnDef="deliveryDateTime">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Delivery Date &Time </th>
                    <td mat-cell *matCellDef="let element"> {{formatDateTime(element?.orderHistory[0].timestamp)}} </td>
                </ng-container>

                <ng-container matColumnDef="arrivalTime">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Arrival Time </th>
                    <td mat-cell *matCellDef="let element"> {{element?.arrivalTime}} min </td>
                </ng-container>

                <ng-container matColumnDef="rider">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Rider </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryDetails?.deliveryPartner?.deliveryExecutive?.name}} </td>
                </ng-container>
                
                <ng-container matColumnDef="invoice">
                    <th mat-header-cell *matHeaderCellDef class="small-heading">Delivery Charges </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element?.orderCost?.deliveryCharges}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons (page)="pageChanged($event)"
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </div>
</div>