<app-layout>
    <div class="main-container">
        <div class="form-header-container d-flex align-items-center">
            <h3 class="heading font-bold">My Account - Change Password</h3>
            <span class="badge ms-2" ></span>
        </div>
        <div class="form-main-container">
            <div class="form-inner-container">
             <form class="form-main" [formGroup]="resetPasswordForm" (ngSubmit)="changePassword()">
                <mat-card class="p-3">
                    <!-- Old Password -->
                    <div class="form-main-inner row">
                      <div class="col-lg-4 col-sm-4 col-xs-12 form-field ">
                        <mat-form-field appearance="outline" class="w-100 mb-1">
                          <mat-label>Current Password</mat-label>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <input [type]="isPasswordHidden ? 'password' : 'text'" matInput formControlName="oldPassword" placeholder="Enter Old Password" />
                            <mat-icon class="eyeIconColor" (click)="isPasswordHidden = !isPasswordHidden">{{isPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </div>
                          <mat-error *ngIf="resetPasswordForm.get('oldPassword')?.invalid && resetPasswordForm.get('oldPassword')?.touched">
                            Old Password is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                
                    <!-- New Password -->
                    <div class="form-main-inner row">
                      <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                        <mat-form-field appearance="outline" class="w-100 mb-1">
                          <mat-label>New Password</mat-label>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <input [type]="newPasswordHidden ? 'password' : 'text'" matInput formControlName="newPassword" placeholder="Enter New Password" />
                            <mat-icon class="eyeIconColor" (click)="newPasswordHidden = !newPasswordHidden">{{newPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </div>
                          <mat-error *ngIf="resetPasswordForm.get('newPassword')?.invalid && resetPasswordForm.get('newPassword')?.touched && !resetPasswordForm.get('newPassword')?.hasError('sameAsOld')">
                            New Password is required and must be at least 8 characters long
                          </mat-error>
                          <mat-error *ngIf="resetPasswordForm.get('newPassword')?.hasError('sameAsOld')">
                            Old and New Passwords must be different
                          </mat-error>
                        </mat-form-field>
                      </div>
                
                      <!-- Re-enter New Password -->
                      <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                        <mat-form-field appearance="outline" class="w-100 mb-1">
                          <mat-label>Re-enter New Password</mat-label>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <input [type]="confirmPasswordHidden ? 'password' : 'text'" matInput formControlName="reenterPassword" placeholder="Re-enter New Password" />
                            <mat-icon class="eyeIconPlacement" (click)="confirmPasswordHidden = !confirmPasswordHidden">{{confirmPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </div>
                          <mat-error *ngIf="resetPasswordForm.hasError('passwordMismatch')">
                            Passwords do not match
                          </mat-error>
                          <mat-error *ngIf="resetPasswordForm?.get('reenterPassword')?.invalid && resetPasswordForm?.get('reenterPassword')?.touched">
                            Re-enter New Password is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Update Password Button -->
                      <div class="col-lg-12 col-sm-12 col-xs-12">
                        <button mat-raised-button class="primary-button-color" type="submit" [disabled]="!resetPasswordForm.valid">
                          Update Password
                        </button>
                      </div>
                    </div>
                  </mat-card>
            </form>
            </div>
        </div>

      </div>


      
</app-layout>
