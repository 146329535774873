<div class="main-container">
    <div class="form-header-container">
        <h3 class="heading">Employment Details</h3>
    </div>
    <div class="form-main-container">
        <div class="form-inner-container">
            <form class="form-main" [formGroup]="employmentDetails">
                <div class="form-main-inner row">
                    <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Employee ID</mat-label>
                            <input type="text" formControlName="employeeId" matInput placeholder="Enter Employee ID" />
                            <mat-error *ngIf="
                        
                        employmentDetails.get('employeeId')?.hasError('required')
                      ">Employee ID is required</mat-error>
                      <mat-error *ngIf="employmentDetails.get('employeeId')?.hasError('maxlength')">
                        Employee ID cannot be more than 10 characters
                      </mat-error>
                        </mat-form-field>

                    </div>

                    
                    <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Designation</mat-label>
                            <input type="text" formControlName="designation" matInput placeholder="Enter Designation" />
                            <mat-error *ngIf="
                      employmentDetails.get('designation')?.touched &&
                        employmentDetails.get('designation')?.hasError('required')
                      ">Designation is required</mat-error>
                       <mat-error *ngIf="employmentDetails.get('designation')?.hasError('maxlength') || employmentDetails.get('designation')?.hasError('minlength')">
                        Designation must be in 3-50 characters
                      </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Joining Date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="joiningDate" readonly>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="employmentDetails.get('joiningDate')?.hasError('required')">
                                Joining Date is required
                            </mat-error>

                        </mat-form-field>
                    </div>


                </div>

                <div>
                    <div class="form-header-container">
                        <h3 class="heading">Vehicle Information</h3>
                    </div>

                    <div class="form-main-inner row">

                        <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Vehicle Type</mat-label>
                                <mat-select formControlName="vehicleType" required>
                                    <mat-option value="cars">Cars</mat-option>
                                    <mat-option value="trucks">Trucks</mat-option>
                                    <mat-option value="motorcycles">Motorcycles</mat-option>
                                </mat-select>
                                <mat-error *ngIf="employmentDetails.get('vehicleType')?.hasError('required')">Vehicle Type is
                                    Required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 col-sm-4 col-xs-12 form-field">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Vehicle Registration Number</mat-label>
                                <input type="text" formControlName="vehicleRegistrationNumber" matInput
                                    placeholder="Enter Vehicle Registration Number" />
                                <mat-error *ngIf="
            employmentDetails.get('vehicleRegistrationNumber')?.touched &&
            employmentDetails.get('vehicleRegistrationNumber')?.hasError('required')
          ">Vehicle Registration Number is required</mat-error>
           <mat-error *ngIf="
           employmentDetails.get('vehicleRegistrationNumber')?.touched &&
           employmentDetails.get('vehicleRegistrationNumber')?.hasError('pattern')
         ">Vehicle Registration Number is invalid (eg. MH12AA1111)</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 col-sm-4 col-xs-12 form-field mb-2">
                            <span>Upload Vehicle Insurance</span>
                            <div class="pt-2 ">
                                <div class="imageInput" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="1rem">
                                    <img 
                                    [src]="vehicleInsurance" />
                                      <div class="edit-img-icon">
                                        <input id="fileInput" formControlName="vehicleInsurance"  type="file"
                          (change)="onFileChangeForVehicleInsurance($event,vehicleIns)" accept="image/*" />
                                        <label for="fileInput">
                                          Choose File
                                        </label>
                                      </div>                                   
                                  </div>                          
                              <!-- *ngIf="vendorDetails?.legalDetails?.panCard" [href]="vendorDetails?.legalDetails?.panCard" -->
                              
                            </div>
                            <mat-error *ngIf="
                              employmentDetails.get('vehicleInsurance')?.touched &&
                              employmentDetails.get('vehicleInsurance')?.hasError('required')
                            ">Vehicle Insurance is required</mat-error>
                          </div>

                    </div>


                </div>

                <div class="form-main-action" fxLayoutGap="1rem">
                    <button mat-raised-button class="primary-button-color" [disabled]="!validInfo() || !isImageUploaded" matStepperNext (click)="updateEmploymentDetails()">
                        Save & Continue
                    </button>
                    <button mat-raised-button class="primary-button-color" matStepperPrevious >
                        Back
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>