<div class="main-container">
    <div class="semiHeading">
      <h3>Training Acknowledgment</h3>
    </div>
    <div class="form-main-container">
      <div class="form-inner-container">
        <div class="form-main" fxLayout="column">
          <mat-checkbox [(ngModel)]="confirmationOfTrainingCompletion">Confirmation of training completion on your platform's processes and policies <strong>*</strong></mat-checkbox>
          <mat-checkbox [(ngModel)]="agreeToMeetMetricsAndTimelines">Agree to meet performance metrics & timelines <strong>*</strong></mat-checkbox>
        </div>
  
        <div fxLayout="row" fxLayoutAlign="space-evenly" class="buttonCls">
          <button mat-flat-button class="primary-button-color" [disabled]="!allCheckboxesChecked()" (click)="updateTrainingAcknowledgement()">Submit</button>
          <button mat-raised-button class="primary-button-color" matStepperPrevious >
            Back
        </button>
        </div>
      </div>
    </div>
  </div>
  