<mat-toolbar class="toolbar justify-space-between">
    <div fxLayout="row" class="d-flex align-items-center">
        <button mat-icon-button (click)="toggleExpansion()"><mat-icon>menu</mat-icon></button>
        <h1 class="app-name m-0">PMS</h1>
    </div>
    <div fxLayout="column" *ngIf="this.partnerType === 'vendor'">
        <div class="text-bold">
            {{userData.name}}
        </div>
    </div>

    <div class="imageProfile d-flex justify-content-center align-items-baseline">
        <span matBadge="{{notificationCount}}" matBadgeColor="accent" class="m-2">
            <img id="notification" class="m-2" src="./../../../../assets/Notification/notifications_bell.svg"
                (click)="onNotifications()">
        </span>
        <mat-slide-toggle *ngIf="this.partnerType === 'vendor'" class="VendorStatus" [(ngModel)]="isChecked"
            (ngModelChange)=" onToggleChange($event)"><span class="d-none d-md-inline"
                [ngClass]="{'online': isChecked, 'offline': !isChecked}">{{
                isChecked ? 'Online' : 'Offline'
                }}</span></mat-slide-toggle>
        <img id="profile" (click)="profile()" src="./../../../../assets/Frame.svg">
    </div>
    <div class="dropDown">
        <mat-card *ngIf="showDropdown">
            <div fxLayout="column">
                <div class="dropDownData">
                    <button mat-flat-button (click)="navigateTo('myProfile')" fxLayout="row"
                        fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            <img src="./../../../../assets/person.svg">
                            <h4>My Profile</h4>
                        </div>
                    </button>
                </div>
                <div fxLayout="column" *ngIf="this.partnerType === 'vendor'">
                    <div class="dropDownData">
                        <button mat-flat-button (click)="navigateTo('businessHours')" fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxLayout="row" fxLayoutGap="1rem">
                                <img src="./../../../../assets/time.svg">
                                <h4>Set Business Hours</h4>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="dropDownData">
                    <button mat-flat-button fxLayout="row" fxLayoutAlign="start center" (click)="changePassword()">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            <img src="./../../../../assets/lock_reset.svg">
                            <h4>Change Password</h4>
                        </div>
                    </button>
                </div>
                <div class="dropDownData">
                    <button mat-flat-button (click)="logout()" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutGap="1rem">
                            &nbsp;
                            <img src="./../../../../assets/logout.svg">&nbsp;
                            <h4>Logout</h4>
                        </div>
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="dropDown" *ngIf="notifications?.length > 0">
        <mat-card *ngIf="showNotifications" class="border orders-box notification-list">
            <div fxLayout="column">
                <mat-card class="dropDownData m-1"*ngFor="let notification of notifications; let i = index">
                    <div mat-flat-button (click)="navigateTo(notification)" fxLayout="row" fxLayoutAlign="start center">
                        <img class="m-2" [src]="notification?.orderDetails?.orderItems[0]?.coverImage" alt="image"
                            height="35px" width="35px">
                        <div class="d-flex justify-space-between flex-col notification-card">
                            <h4 class="m-1">{{notification.message}}</h4>
                            <div *ngIf="notification.message.includes('New order received')">
              <div class="m-1 d-flex">
                <h4>Order Items:</h4>
                <h4>{{ notification?.orderDetails?.orderItems.length }}</h4>
              </div>
              <div class="m-1 d-flex common-card" *ngIf="notification?.orderDetails?.orderID">
                <h4>Order ID:</h4>
                <h4>{{ notification?.orderDetails?.orderID }}</h4>
              </div>
            </div>

            <div *ngIf="notification.message.includes('Consider restocking')">
              <div class="m-1 d-flex">
                <h4>Items:</h4>
                <h4>{{ notification?.orderDetails?.orderItems.length }}</h4>
              </div>
              <div class="m-1 d-flex common-card" *ngIf="notification?.productDetails?.contentId">
                <h4>Product ID:</h4>
                <h4>{{ notification?.productDetails?.contentId }}</h4>
              </div>
            </div>

                            <button mat-button class="text-bg-danger m-1 delete-btn" (click)="deleteNotification(i)">Delete</button>
                        </div>
                    </div>
                </mat-card>
                <div class="clear-order d-flex justify-content-center align-items-center flex-row ">
                    <button mat-button class="text-bg-warning" (click)="clearAllOrder()">Clear All</button>
                </div>
            </div>
        </mat-card>
    </div>




</mat-toolbar>
