<app-header (toggleSidenav)="onSidenavToggle($event)"></app-header>
<mat-sidenav-container class="container m-0" autosize>
    <mat-sidenav #sidenav class="sidenav" mode="side" [(opened)]="isSidenavOpened"
        (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
        <mat-nav-list>
            <mat-list-item class="p-1" routerLinkActive="active-link" routerLink="/dashboard">
                <div class="d-flex">
                    <mat-icon mat-list-icon class="primary-icon-color">dashboard</mat-icon>
                    <a class="full-width text-decoration-none" *ngIf="isExpanded || isShowing">&nbsp;Dashboard </a>
                </div>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu2 = !showSubmenu2" class="parent p-1"
                *ngIf="userRole === 'delivery_partner'" routerLinkActive="active-link">
                <mat-icon mat-list-icon class="primary-icon-color"> directions_bike</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Delivery Executive</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu2}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu2}"
                *ngIf="isShowing || isExpanded && userRole === 'delivery_partner'">
                <mat-list-item routerLinkActive="active-link" routerLink="/delivery-executive/executive-list">
                    <a>Executive List</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/delivery-executive/rider-list">
                    <a>Rider List</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/delivery-executive/ongoing-task">
                    <a>Ongoing Task</a>
                </mat-list-item>
                <!-- <mat-list-item routerLinkActive="active-link" routerLink="/delivery-executive/executive-list">
                    <a>Map</a>
                </mat-list-item> -->
            </div>
            <mat-list-item (click)="showSubmenu4 = !showSubmenu4" class="parent p-1"
                *ngIf="userRole === 'delivery_partner'" routerLinkActive="active-link">
                <mat-icon mat-list-icon class="primary-icon-color">settings</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Setup & Configuration</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu4}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu4}"
                *ngIf="isShowing || isExpanded && userRole === 'delivery_partner'">
                <mat-list-item routerLinkActive="active-link" routerLink="/delivery-executive/commission-slab">
                    <a>Commission Slab</a>
                </mat-list-item>
            </div>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent p-1" *ngIf="userRole === 'vendor'"
                routerLinkActive="active-link">
                <mat-icon mat-list-icon class="primary-icon-color">storefront</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Product Catalogue</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}"
                *ngIf="isShowing || isExpanded && userRole === 'vendor'">
                <mat-list-item routerLinkActive="active-link" routerLink="/product-catalogue/add-edit-product">
                    <a>Create Product</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/product-catalogue/catalogue-list">
                    <a>Catalogue List</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/product-catalogue/complete-your-draft">
                    <a>Complete Your Draft</a>
                </mat-list-item>
            </div>
            <mat-list-item (click)="showSubmenu3 = !showSubmenu3" class="parent p-1" *ngIf="userRole === 'vendor'"
                routerLinkActive="active-link">
                <mat-icon mat-list-icon class="primary-icon-color">border_all</mat-icon>
                <span class="full-width" *ngIf="isExpanded || isShowing">Order Management</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu3}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu3}"
                *ngIf="isShowing || isExpanded && userRole === 'vendor'">
                <mat-list-item routerLinkActive="active-link" routerLink="/order-management/order-list">
                    <a>Order list</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/order-management/discount-list">
                    <a>Discount list</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/order-management/manage-order-list">
                    <a>Manage Orders</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link"
                    routerLink="/product-catalogue/manage-pricing-and-inventory">
                    <a>Manage Pricing & Inventory</a>
                </mat-list-item>
                <mat-list-item routerLinkActive="active-link" routerLink="/product-catalogue/out-of-stock-list"
                    class="">
                    <a class="low-stock">Out of Stock {{totalOutOfStock }}</a>
                </mat-list-item>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <div class="p-3">
        <ng-content></ng-content>
    </div>
</mat-sidenav-container>